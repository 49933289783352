import { Translations } from '../types';

const translations: Translations = {
  common: {
    loading: 'Laden...',
    error: 'Fehler',
    success: 'Erfolg',
    cancel: 'Abbrechen',
    navigation: {
      dashboard: 'Dashboard',
      roles: 'Rollen',
      knowledge: 'Wissen',
      accounts: 'Konten',
      team: 'Team',
      settings: 'Einstellungen',
      logout: 'Abmelden',
    },
    header: {
      title: 'Admin-Dashboard',
      switchTheme: 'Zu {{mode}}-Modus wechseln',
    },
    labels: {
      name: 'Name',
      email: 'E-Mail',
      description: 'Beschreibung',
      prompt: 'Prompt',
      default: 'Standard',
      owner: 'Besitzer',
    },
    actions: {
      add: 'Hinzufügen',
      edit: 'Bearbeiten',
      delete: 'Löschen',
      confirm: 'Sind Sie sicher?',
      copy: 'Kopieren',
      remove: 'Entfernen',
    },
  },
  auth: {
    login: {
      title: 'Willkommen bei Ellie',
      subtitle: 'Melden Sie sich an, um auf Ihr Dashboard zuzugreifen',
      email: 'E-Mail',
      password: 'Passwort',
      signIn: 'Anmelden',
      or: 'ODER',
      continueWithGoogle: 'Mit Google fortfahren',
      continueWithMicrosoft: 'Mit Microsoft fortfahren',
      noAccount: 'Noch kein Konto?',
      signUp: 'Registrieren',
      initializing: 'Initialisierung...',
      errors: {
        invalidCredentials: 'Ungültige E-Mail oder Passwort',
        googleFailed: 'Google-Anmeldung fehlgeschlagen',
        microsoftFailed: 'Microsoft-Anmeldung fehlgeschlagen',
        initializationFailed: 'Initialisierung der Authentifizierung fehlgeschlagen',
        interactionInProgress: 'Ein anderer Anmeldeversuch läuft. Bitte warten Sie einen Moment und versuchen Sie es erneut.',
        popupBlocked: 'Anmelde-Popup wurde blockiert. Bitte erlauben Sie Pop-ups für diese Seite.',
        redirectFailed: 'Weiterleitung zur Anmeldeseite fehlgeschlagen. Bitte versuchen Sie es erneut.',
        accountSelectionFailed: 'Kontoauswahl fehlgeschlagen. Bitte versuchen Sie es erneut.',
        tokenAcquisitionFailed: 'Authentifizierungstoken konnte nicht abgerufen werden. Bitte versuchen Sie es erneut.'
      },
      messages: {
        redirecting: 'Weiterleitung zur Anmeldeseite...',
        authenticating: 'Authentifizierung...',
        loggingOut: 'Abmelden...'
      }
    }
  },
  accounts: {
    title: 'E-Mail-Konten',
    buttons: {
      connectGmail: 'Gmail verbinden',
      connectOutlook: 'Outlook verbinden',
    },
    emptyState: {
      title: 'Beginnen Sie mit der Verbindung Ihres Kontos',
      description: 'Ellie kategorisiert automatisch Ihre E-Mails und erstellt Antwortentwürfe. Ellie sendet niemals E-Mails ohne Ihre Überprüfung.',
    },
    status: {
      lastSynced: 'Zuletzt synchronisiert: {{time}}',
    },
    actions: {
      disconnect: 'Verbindung trennen',
    },
    messages: {
      disconnectConfirm: 'Sind Sie sicher, dass Sie die Verbindung zu Ihrem {{provider}}-Konto trennen möchten?',
      disconnectSuccess: 'Verbindung zum {{provider}}-Konto erfolgreich getrennt',
      errors: {
        loadFailed: 'E-Mail-Konten konnten nicht geladen werden',
        disconnectFailed: 'Verbindung zum {{provider}}-Konto konnte nicht getrennt werden',
        connectFailed: 'Verbindung zum {{provider}}-Konto konnte nicht hergestellt werden',
        authFailed: 'Authentifizierung fehlgeschlagen',
      },
    },
  },
  dashboard: {
    title: 'Dashboard',
    welcome: 'Willkommen, {{name}}',
    stats: {
      usageStatistics: 'Nutzungsstatistiken',
      repliesToday: 'Antworten heute',
      totalReplies: 'Gesamte Antworten',
      costToday: 'Kosten heute',
      teamInformation: 'Team-Informationen',
      plan: 'Plan',
      members: 'Mitglieder',
      role: 'Rolle',
      teamOwner: 'Team-Besitzer',
      teamMember: 'Team-Mitglied',
      trainingStatus: 'Trainingsstatus',
      status: 'Status',
      messagesProcessed: 'Verarbeitete Nachrichten',
      processingCost: 'Verarbeitungskosten',
      tokenUsage: 'Token-Nutzung',
      promptTokens: 'Prompt-Token',
      completionTokens: 'Antwort-Token',
      totalTokens: 'Gesamte Token',
    },
  },
  roles: {
    title: 'Rollen',
    addRole: 'Rolle hinzufügen',
    editRole: 'Rolle bearbeiten',
    defaultRole: 'Standard',
    setAsDefault: 'Als Standard festlegen',
    clearDefault: 'Standard entfernen',
    deleteConfirm: 'Sind Sie sicher, dass Sie diese Rolle löschen möchten?',
    messages: {
      fetchError: 'Rollen konnten nicht geladen werden',
      createError: 'Rolle konnte nicht erstellt werden',
      updateError: 'Rolle konnte nicht aktualisiert werden',
      deleteError: 'Rolle konnte nicht gelöscht werden',
      setDefaultSuccess: '{{name}} ist jetzt die Standardrolle',
      clearDefaultSuccess: '{{name}} ist nicht mehr die Standardrolle',
    },
  },
  knowledge: {
    title: 'Wissensbasis',
    websiteTraining: {
      title: 'Ellie von einer Website trainieren',
    },
  },
  team: {
    title: 'Team-Verwaltung',
    addMember: {
      title: 'Team-Mitglied hinzufügen',
      button: 'Mitglied hinzufügen',
    },
    inviteLink: {
      title: 'Team-Einladungslink',
      description: 'Teilen Sie diesen Link mit Personen, die Sie in Ihr Team einladen möchten',
      copied: 'Einladungslink kopiert',
    },
    stats: {
      title: 'Team-Statistiken',
      totalMembers: 'Gesamte Mitglieder',
      allowedMembers: 'Erlaubte Mitglieder',
      availableSlots: 'Verfügbare Plätze',
    },
    member: {
      joined: 'Beigetreten: {{date}}',
      ownerLabel: 'Besitzer',
      ownerCannotBeRemoved: 'Team-Besitzer kann nicht entfernt werden',
      removeConfirm: 'Sind Sie sicher, dass Sie {{name}} aus dem Team entfernen möchten?',
    },
    messages: {
      fetchError: 'Team-Daten konnten nicht geladen werden',
      addSuccess: 'Team-Mitglied erfolgreich hinzugefügt',
      addError: 'Team-Mitglied konnte nicht hinzugefügt werden',
      removeSuccess: 'Team-Mitglied erfolgreich entfernt',
      removeError: 'Team-Mitglied konnte nicht entfernt werden',
    },
  },
  settings: {
    title: 'Einstellungen',
  },
};

export default translations; 