import axios, { InternalAxiosRequestConfig } from 'axios';
import * as msal from '@azure/msal-browser';

declare global {
  interface Window {
    google: {
      accounts: {
        id: {
          initialize: (config: {
            client_id: string;
            auto_select?: boolean;
            callback: (response: { credential?: string }) => void;
            context?: string;
            ux_mode?: string;
            use_fedcm_for_prompt?: boolean;
            native_callback?: boolean;
          }) => void;
          renderButton: (element: HTMLElement, config: {
            type?: 'standard' | 'icon';
            theme?: 'outline' | 'filled_blue' | 'filled_black';
            size?: 'large' | 'medium' | 'small';
          }) => void;
          prompt: (momentListener?: (notification: {
            isNotDisplayed: () => boolean;
            isSkippedMoment: () => boolean;
            getNotDisplayedReason: () => string;
            getSkippedReason: () => string;
          }) => void) => void;
          cancel: () => void;
        };
        oauth2: {
          initTokenClient(config: {
            client_id: string;
            scope: string;
            callback: (response: { access_token?: string }) => void;
          }): {
            requestAccessToken(): void;
          };
        };
      };
    };
    msal: {
      PublicClientApplication: any;
      InteractionRequiredAuthError: any;
    };
  }
}

const api = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
});

// Request interceptor to add auth token with JWT prefix
api.interceptors.request.use((config: InternalAxiosRequestConfig) => {
  const token = localStorage.getItem('auth_token');
  if (token) {
    config.headers.Authorization = `JWT ${token}`;
  }
  return config;
});

// Types based on API schema
export interface User {
  uuid: string;
  email: string;
  licenceKey: string;
  teamUuid: string;
  isTeamOwner: boolean;
  billing?: {
    team: boolean;
    plan: string;
  };
  team: {
    members: number;
    membersRemaining: number;
    createdAt: string;
  };
  usage: {
    generatedRepliesLimit: number;
    emailTrainingLimit: number;
    repliesGeneratedToday: number;
    totalRepliesGenerated: number;
    promptTokens: number;
    completionTokens: number;
    totalTokens: number;
    estimatedCostUSDToday: number;
  };
  training: {
    trainingEmail: string;
    fineTuneId: string | null;
    filename: string | null;
    emailsReceivedCount: number;
    error: string | null;
    processingCost: number;
    processingMessages: number;
    status: string;
  };
  meta: {
    lastUpdatedAt: string;
  };
}

export interface Role {
  name: string;
  description: string;
  prompt: string;
  uuid: string;
  isDefault: boolean;
}

export interface CreateRoleRequest {
  name: string;
  description: string;
  prompt: string;
  isDefault: boolean;
}

export interface CorpusSummary {
  uuid: string;
  type: 'website' | 'file';
  fileName: string | null;
  websiteUrl: string | null;
  status: 'finished' | 'processing' | 'error' | 'crawling';
  stats: {
    urlsCrawled: number;
  };
  meta: {
    createdAt: string;
    lastUpdatedAt: string;
  };
  embeddingIds: string[];
  userUuid: string;
  urlsCrawled?: string[];
}

export interface TeamMember {
  uuid: string;
  email: string;
  name: string;
  joinedAt?: string;
  isTeamOwner: boolean;
}

export interface TeamStats {
  totalMembers: number;
  allowedMembers: number;
  availableSlots: number;
}

export interface ApiError {
  error: string;
}

export interface WebsiteTrainingRequest {
  url: string;
  excludeUrls?: string[];
}

export type EmailProvider = 'gmail' | 'outlook';

export interface EmailAccount {
  provider: EmailProvider;
  email: string;
  lastUpdated: string;
}

export interface EmailAccountStatus {
  provider: EmailProvider;
  email: string;
  lastUpdated: string;
  lastSyncedAt: string;
  lastSyncError: string | null;
  syncStatus: 'syncing' | 'error' | 'success' | 'unknown';
  isConnected: boolean;
}

export interface LoginRequest {
  email: string;
  password: string;
}

export interface RegisterRequest {
  email: string;
  password: string;
}

export interface AuthResponse {
  token: string;
}

export interface GoogleAuthResponse extends AuthResponse {
  user: {
    email: string;
    name: string;
    picture?: string;
  };
}

export interface TrainingAttachmentLog {
  attachmentId: string;
  status: 'pending' | 'processed' | 'failed' | 'skipped';
  error?: string;
  reason?: string;
  contentType: string;
  processingCompletedAt?: string;
}

export interface TrainingEmailLog {
  userId: string;
  emailId: string;
  receivedAt: string;
  subject: string;
  from: string;
  totalAttachments: number;
  validAttachments: number;
  attachments: TrainingAttachmentLog[];
}

export interface TrainingStatus {
  filename: string;
  fineTuneCost: number;
  fineTuneId: string | null;
  messageCount: number;
  processingCost: number;
  processingMessages: number;
  trainingEmail: string;
  emailsReceivedCount: number;
  status: string;
  fineTuneStatus: string | null;
}

// API endpoints
export const rolesApi = {
  getRoles: () => api.get<Role[]>('/roles').then(response => response.data),
  createRole: (role: CreateRoleRequest) => api.post<Role>('/roles', role).then(response => response.data),
  updateRole: (uuid: string, role: Partial<Role>) => api.put<Role>(`/roles/${uuid}`, role).then(response => response.data),
  deleteRole: (uuid: string) => api.delete(`/roles/${uuid}`),
  setDefault: (uuid: string, currentDefaultUuid: string | null) => api.patch<Role>(`/roles/${uuid}`, { 
    op: 'set-default',
    data: {
      defaultUuid: currentDefaultUuid
    }
  }).then(response => response.data),
  clearDefault: (uuid: string) => api.patch<Role>(`/roles/${uuid}`, { op: 'clear-default' }).then(response => response.data),
};

export const knowledgeApi = {
  getCorpusSummaries: () => api.get<CorpusSummary[]>('/corpus/summaries'),
  uploadCorpus: (file: File) => {
    const formData = new FormData();
    formData.append('file', file);
    return api.post('/corpus/upload', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  },
  trainFromWebsite: (data: WebsiteTrainingRequest) => 
    api.post('/corpus/crawl', data),
  deleteCorpus: (id: string) => api.delete(`/corpus/${id}`),
  getTrainingStatus: () => api.get<TrainingStatus>('/training/status'),
  getTrainingEmails: () => api.get<TrainingEmailLog[]>('/training/emails'),
};

export const teamApi = {
  getMembers: () => api.get<TeamMember[]>('/team/members').then(response => response.data),
  addMember: (data: { email: string; name: string }) => 
    api.post<TeamMember>('/team/members', data).then(response => response.data),
  removeMember: (uuid: string) => api.delete(`/team/members/${uuid}`),
  getStats: () => api.get<TeamStats>('/team/stats').then(response => response.data),
};

export const userApi = {
  getCurrentUser: () => api.get<User>('/me').then(response => response.data),
  createPortalSession: (data: { flowDataType: string; subscriptionUpdateConfig?: any }) =>
    api.post('/portal/session', data).then(response => response.data),
};

export const emailApi = {
  getAccounts: () => api.get<EmailAccount[]>('/email/accounts').then(response => response.data),
  getAccountStatus: (provider: EmailProvider) => 
    api.get<EmailAccountStatus>(`/email/accounts/${provider}/status`).then(response => response.data),
  disconnectAccount: (provider: EmailProvider) => 
    api.delete(`/email/accounts/${provider}`).then(response => response.data),
  getAuthUrl: (provider: EmailProvider) => 
    api.get<{ authUrl: string }>(`/${provider}/auth`).then(response => response.data),
};

export const authApi = {
  login: (data: LoginRequest) => 
    api.post<AuthResponse>('/auth/login', data).then(response => response.data),
  register: (data: RegisterRequest) => 
    api.post<AuthResponse>('/auth/register', data).then(response => response.data),
  loginWithGoogle: (idToken: string) => 
    api.post<GoogleAuthResponse>('/auth/google', { idToken }).then(response => response.data),
  loginWithMicrosoft: (accessToken: string) => 
    api.post<AuthResponse>('/auth/microsoft', { accessToken }).then(response => response.data),
};

export const googleAuthApi = {
  initialize: async (clientId: string) => {
    // Load the Google Sign-In library dynamically
    await new Promise((resolve) => {
      const script = document.createElement('script');
      script.src = 'https://accounts.google.com/gsi/client';
      script.async = true;
      script.defer = true;
      script.onload = () => {
        window.google.accounts.id.initialize({
          client_id: clientId,
          callback: () => {}, // We'll set this in signIn
        });
        resolve(undefined);
      };
      document.head.appendChild(script);
    });
  },
  
  signIn: async (): Promise<string> => {
    return new Promise((resolve, reject) => {
      const handleCredential = (response: { credential?: string }) => {
        if (response?.credential) {
          resolve(response.credential);
        } else {
          reject(new Error('Failed to get ID token'));
        }
      };

      try {
        // Initialize with callback
        window.google.accounts.id.initialize({
          client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID as string,
          callback: handleCredential,
        });

        // Create container for the button
        const buttonContainer = document.createElement('div');
        buttonContainer.id = 'google-signin-button';
        buttonContainer.style.display = 'none';
        document.body.appendChild(buttonContainer);

        // Render the button
        window.google.accounts.id.renderButton(
          buttonContainer,
          { theme: 'outline', size: 'large' }
        );

        // Programmatically click the button
        const button = buttonContainer.querySelector('div[role="button"]') as HTMLElement;
        if (button) {
          button.click();
        } else {
          // If button click fails, try the prompt
          window.google.accounts.id.prompt((notification) => {
            if (notification.isNotDisplayed()) {
              reject(new Error(`Sign-In not displayed: ${notification.getNotDisplayedReason()}`));
            }
            if (notification.isSkippedMoment()) {
              reject(new Error(`Sign-In skipped: ${notification.getSkippedReason()}`));
            }
          });
        }

        // Clean up after 5 seconds or on resolve/reject
        setTimeout(() => {
          if (buttonContainer.parentNode) {
            buttonContainer.parentNode.removeChild(buttonContainer);
          }
        }, 5000);
      } catch (error) {
        console.error('Google Sign-In error:', error);
        reject(error);
      }
    });
  },

  loginWithGoogle: async (idToken: string) => 
    api.post<GoogleAuthResponse>('/auth/google', { idToken })
      .then(response => response.data),
};

export const microsoftAuthApi = {
  msalInstance: null as msal.PublicClientApplication | null,
  isInitialized: false,

  createMsalInstance: () => {
    const msalConfig: msal.Configuration = {
      auth: {
        clientId: process.env.REACT_APP_MICROSOFT_CLIENT_ID as string,
        authority: `https://login.microsoftonline.com/${process.env.REACT_APP_MICROSOFT_TENANT_ID || 'common'}`,
        redirectUri: window.location.origin,
        navigateToLoginRequestUrl: true,
      },
      cache: {
        cacheLocation: 'localStorage',
        storeAuthStateInCookie: false,
        claimsBasedCachingEnabled: true
      },
      system: {
        allowNativeBroker: false,
        windowHashTimeout: 60000,
        iframeHashTimeout: 6000,
        loadFrameTimeout: 0,
        loggerOptions: {
          logLevel: msal.LogLevel.Info,
          loggerCallback: (level: msal.LogLevel, message: string, containsPii: boolean) => {
            if (containsPii) return;
            switch (level) {
              case msal.LogLevel.Error: console.error(message); break;
              case msal.LogLevel.Warning: console.warn(message); break;
              case msal.LogLevel.Info: console.info(message); break;
              case msal.LogLevel.Verbose: console.debug(message); break;
              default: console.log(message);
            }
          }
        }
      }
    };

    return new msal.PublicClientApplication(msalConfig);
  },

  initialize: async () => {
    // If already initialized, return immediately
    if (microsoftAuthApi.isInitialized) {
      return Promise.resolve();
    }

    try {
      microsoftAuthApi.msalInstance = microsoftAuthApi.createMsalInstance();
      await microsoftAuthApi.msalInstance.initialize();
      microsoftAuthApi.isInitialized = true;

      // Handle the redirect flow on initialization
      try {
        const result = await microsoftAuthApi.msalInstance.handleRedirectPromise();
        if (result?.accessToken) {
          return result.accessToken;
        }
      } catch (error) {
        console.error('Error handling redirect:', error);
      }
    } catch (error) {
      console.error('Failed to initialize MSAL:', error);
      throw error;
    }
  },

  signIn: async (): Promise<string> => {
    if (!microsoftAuthApi.isInitialized || !microsoftAuthApi.msalInstance) {
      await microsoftAuthApi.initialize();
    }

    const loginRequest: msal.PopupRequest = {
      scopes: ['openid', 'profile', 'email', 'User.Read'],
      prompt: 'select_account',
    };

    try {
      // Clear all accounts and cache before starting new login
      const accounts = microsoftAuthApi.msalInstance!.getAllAccounts();
      for (const account of accounts) {
        microsoftAuthApi.msalInstance!.setActiveAccount(null);
        try {
          // Clear cache for this account
          await microsoftAuthApi.msalInstance!.clearCache({
            account: account
          });
        } catch (e) {
          console.warn('Failed to clear cache for account:', e);
        }
      }

      // Start fresh interactive login with popup
      const result = await microsoftAuthApi.msalInstance!.loginPopup(loginRequest);
      if (result) {
        microsoftAuthApi.msalInstance!.setActiveAccount(result.account);
        const tokenResult = await microsoftAuthApi.msalInstance!.acquireTokenSilent({
          ...loginRequest,
          account: result.account
        });
        return tokenResult.accessToken;
      }
      throw new Error('Failed to get access token');
    } catch (error) {
      console.error('Microsoft Sign-In error:', error);
      throw error;
    }
  },

  logout: async () => {
    if (!microsoftAuthApi.isInitialized || !microsoftAuthApi.msalInstance) {
      return;
    }

    const account = microsoftAuthApi.msalInstance.getActiveAccount();
    if (account) {
      microsoftAuthApi.msalInstance.setActiveAccount(null);
      await microsoftAuthApi.msalInstance.logoutRedirect({
        account: account,
        postLogoutRedirectUri: window.location.origin
      });
    }
  }
};

export default api; 