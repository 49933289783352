import { Translations } from '../types';

const translations: Translations = {
  common: {
    loading: 'Chargement...',
    error: 'Erreur',
    success: 'Succès',
    cancel: 'Annuler',
    navigation: {
      dashboard: 'Tableau de bord',
      roles: 'Rôles',
      knowledge: 'Connaissances',
      accounts: 'Comptes',
      team: 'Équipe',
      settings: 'Paramètres',
      logout: 'Déconnexion',
    },
    header: {
      title: 'Tableau de bord administrateur',
      switchTheme: 'Passer au mode {{mode}}',
    },
    labels: {
      name: 'Nom',
      email: 'E-mail',
      description: 'Description',
      prompt: 'Prompt',
      default: 'Par défaut',
      owner: 'Propriétaire',
    },
    actions: {
      add: 'Ajouter',
      edit: 'Modifier',
      delete: 'Supprimer',
      confirm: 'Êtes-vous sûr ?',
      copy: 'Copier',
      remove: 'Retirer',
    },
  },
  auth: {
    login: {
      title: 'Bienvenue sur Ellie',
      subtitle: 'Connectez-vous pour accéder à votre tableau de bord',
      email: 'E-mail',
      password: 'Mot de passe',
      signIn: 'Se connecter',
      or: 'OU',
      continueWithGoogle: 'Continuer avec Google',
      continueWithMicrosoft: 'Continuer avec Microsoft',
      noAccount: 'Pas encore de compte ?',
      signUp: 'S\'inscrire',
      initializing: 'Initialisation...',
      errors: {
        invalidCredentials: 'E-mail ou mot de passe invalide',
        googleFailed: 'Échec de la connexion avec Google',
        microsoftFailed: 'Échec de la connexion avec Microsoft',
        initializationFailed: 'Échec de l\'initialisation de l\'authentification',
        interactionInProgress: 'Une autre tentative de connexion est en cours. Veuillez patienter un moment et réessayer.',
        popupBlocked: 'La fenêtre de connexion a été bloquée. Veuillez autoriser les popups pour ce site.',
        redirectFailed: 'Échec de la redirection vers la page de connexion. Veuillez réessayer.',
        accountSelectionFailed: 'Échec de la sélection du compte. Veuillez réessayer.',
        tokenAcquisitionFailed: 'Échec de l\'acquisition du jeton d\'authentification. Veuillez réessayer.'
      },
      messages: {
        redirecting: 'Redirection vers la page de connexion...',
        authenticating: 'Authentification...',
        loggingOut: 'Déconnexion...'
      }
    }
  },
  accounts: {
    title: 'Comptes e-mail',
    buttons: {
      connectGmail: 'Connecter Gmail',
      connectOutlook: 'Connecter Outlook',
    },
    emptyState: {
      title: 'Commencez par connecter votre compte',
      description: 'Ellie catégorisera automatiquement vos e-mails et créera des brouillons de réponses. Ellie n\'envoie jamais d\'e-mails sans votre vérification.',
    },
    status: {
      lastSynced: 'Dernière synchronisation : {{time}}',
    },
    actions: {
      disconnect: 'Déconnecter',
    },
    messages: {
      disconnectConfirm: 'Êtes-vous sûr de vouloir déconnecter votre compte {{provider}} ?',
      disconnectSuccess: 'Compte {{provider}} déconnecté avec succès',
      errors: {
        loadFailed: 'Échec du chargement des comptes e-mail',
        disconnectFailed: 'Échec de la déconnexion du compte {{provider}}',
        connectFailed: 'Échec de la connexion au compte {{provider}}',
        authFailed: 'Échec de l\'authentification',
      },
    },
  },
  dashboard: {
    title: 'Tableau de bord',
    welcome: 'Bienvenue, {{name}}',
    stats: {
      usageStatistics: 'Statistiques d\'utilisation',
      repliesToday: 'Réponses aujourd\'hui',
      totalReplies: 'Total des réponses',
      costToday: 'Coût aujourd\'hui',
      teamInformation: 'Informations d\'équipe',
      plan: 'Plan',
      members: 'Membres',
      role: 'Rôle',
      teamOwner: 'Propriétaire d\'équipe',
      teamMember: 'Membre d\'équipe',
      trainingStatus: 'État de l\'entraînement',
      status: 'État',
      messagesProcessed: 'Messages traités',
      processingCost: 'Coût de traitement',
      tokenUsage: 'Utilisation des tokens',
      promptTokens: 'Tokens de prompt',
      completionTokens: 'Tokens de réponse',
      totalTokens: 'Total des tokens',
    },
  },
  roles: {
    title: 'Rôles',
    addRole: 'Ajouter un rôle',
    editRole: 'Modifier le rôle',
    defaultRole: 'Par défaut',
    setAsDefault: 'Définir par défaut',
    clearDefault: 'Retirer par défaut',
    deleteConfirm: 'Êtes-vous sûr de vouloir supprimer ce rôle ?',
    messages: {
      fetchError: 'Échec du chargement des rôles',
      createError: 'Échec de la création du rôle',
      updateError: 'Échec de la mise à jour du rôle',
      deleteError: 'Échec de la suppression du rôle',
      setDefaultSuccess: '{{name}} est maintenant le rôle par défaut',
      clearDefaultSuccess: '{{name}} n\'est plus le rôle par défaut',
    },
  },
  knowledge: {
    title: 'Base de connaissances',
    websiteTraining: {
      title: 'Entraîner Ellie depuis un site web',
    },
  },
  team: {
    title: 'Gestion d\'équipe',
    addMember: {
      title: 'Ajouter un membre',
      button: 'Ajouter un membre',
    },
    inviteLink: {
      title: 'Lien d\'invitation d\'équipe',
      description: 'Partagez ce lien avec les personnes que vous souhaitez inviter dans votre équipe',
      copied: 'Lien d\'invitation copié',
    },
    stats: {
      title: 'Statistiques d\'équipe',
      totalMembers: 'Total des membres',
      allowedMembers: 'Membres autorisés',
      availableSlots: 'Places disponibles',
    },
    member: {
      joined: 'Rejoint le : {{date}}',
      ownerLabel: 'Propriétaire',
      ownerCannotBeRemoved: 'Le propriétaire de l\'équipe ne peut pas être retiré',
      removeConfirm: 'Êtes-vous sûr de vouloir retirer {{name}} de l\'équipe ?',
    },
    messages: {
      fetchError: 'Échec du chargement des données d\'équipe',
      addSuccess: 'Membre ajouté avec succès',
      addError: 'Échec de l\'ajout du membre',
      removeSuccess: 'Membre retiré avec succès',
      removeError: 'Échec du retrait du membre',
    },
  },
  settings: {
    title: 'Paramètres',
  },
};

export default translations; 