import React, { createContext, useContext, useState, ReactNode, useEffect } from 'react';
import { authApi, LoginRequest, RegisterRequest, User, userApi } from '../services/api';

interface AuthContextType {
  user: User | null;
  isAuthenticated: boolean;
  login: (email: string, password: string) => Promise<void>;
  register: (email: string, password: string) => Promise<void>;
  loginWithGoogle: (idToken: string) => Promise<void>;
  loginWithMicrosoft: (accessToken: string) => Promise<void>;
  logout: () => void;
  error: string | null;
}

const AuthContext = createContext<AuthContextType | null>(null);

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};

export const AuthProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [user, setUser] = useState<User | null>(null);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const token = localStorage.getItem('auth_token');
    if (token) {
      fetchUser();
    }
  }, []);

  const fetchUser = async () => {
    try {
      const userData = await userApi.getCurrentUser();
      setUser(userData);
      setError(null);
    } catch (err) {
      console.error('Error fetching user:', err);
      setError('Failed to fetch user data');
      localStorage.removeItem('auth_token');
      setUser(null);
    }
  };

  const handleAuthResponse = async (token: string) => {
    try {
      localStorage.setItem('auth_token', token);
      await fetchUser();
    } catch (err) {
      console.error('Error handling auth response:', err);
      localStorage.removeItem('auth_token');
      setUser(null);
      throw err;
    }
  };

  const login = async (email: string, password: string) => {
    try {
      const { token } = await authApi.login({ email, password });
      await handleAuthResponse(token);
    } catch (err) {
      console.error('Login failed:', err);
      setError('Invalid email or password');
      throw err;
    }
  };

  const register = async (email: string, password: string) => {
    try {
      const { token } = await authApi.register({ email, password });
      await handleAuthResponse(token);
    } catch (err) {
      console.error('Registration failed:', err);
      setError('Registration failed. Email might already be in use.');
      throw err;
    }
  };

  const loginWithGoogle = async (idToken: string) => {
    try {
      const { token } = await authApi.loginWithGoogle(idToken);
      await handleAuthResponse(token);
    } catch (err) {
      console.error('Google login failed:', err);
      setError('Google login failed');
      throw err;
    }
  };

  const loginWithMicrosoft = async (accessToken: string) => {
    try {
      const { token } = await authApi.loginWithMicrosoft(accessToken);
      await handleAuthResponse(token);
    } catch (err) {
      console.error('Microsoft login failed:', err);
      setError('Microsoft login failed');
      throw err;
    }
  };

  const logout = () => {
    localStorage.removeItem('auth_token');
    setUser(null);
    setError(null);
  };

  return (
    <AuthContext.Provider value={{
      user,
      isAuthenticated: !!user,
      login,
      register,
      loginWithGoogle,
      loginWithMicrosoft,
      logout,
      error
    }}>
      {children}
    </AuthContext.Provider>
  );
}; 