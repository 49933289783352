import { Translations } from '../types';

const translations: Translations = {
  common: {
    loading: 'Loading...',
    error: 'Error',
    success: 'Success',
    cancel: 'Cancel',
    navigation: {
      dashboard: 'Dashboard',
      roles: 'Roles',
      knowledge: 'Knowledge',
      accounts: 'Accounts',
      team: 'Team',
      settings: 'Settings',
      logout: 'Logout',
    },
    header: {
      title: 'Admin Dashboard',
      switchTheme: 'Switch to {{mode}} mode',
    },
    labels: {
      name: 'Name',
      email: 'Email',
      description: 'Description',
      prompt: 'Prompt',
      default: 'Default',
      owner: 'Owner',
    },
    actions: {
      add: 'Add',
      edit: 'Edit',
      delete: 'Delete',
      confirm: 'Are you sure?',
      copy: 'Copy',
      remove: 'Remove',
    },
  },
  auth: {
    login: {
      title: 'Welcome to Ellie',
      subtitle: 'Sign in to access your dashboard',
      email: 'Email',
      password: 'Password',
      signIn: 'Sign In',
      or: 'OR',
      continueWithGoogle: 'Continue with Google',
      continueWithMicrosoft: 'Continue with Microsoft',
      noAccount: 'Don\'t have an account?',
      signUp: 'Sign up',
      initializing: 'Initializing...',
      errors: {
        invalidCredentials: 'Invalid email or password',
        googleFailed: 'Failed to login with Google',
        microsoftFailed: 'Failed to login with Microsoft',
        initializationFailed: 'Failed to initialize authentication',
        interactionInProgress: 'Another login attempt is in progress. Please wait a moment and try again.',
        popupBlocked: 'Login popup was blocked. Please allow popups for this site.',
        redirectFailed: 'Failed to redirect to login page. Please try again.',
        accountSelectionFailed: 'Failed to select account. Please try again.',
        tokenAcquisitionFailed: 'Failed to acquire authentication token. Please try again.'
      },
      messages: {
        redirecting: 'Redirecting to login page...',
        authenticating: 'Authenticating...',
        loggingOut: 'Logging out...'
      }
    }
  },
  accounts: {
    title: 'Email Accounts',
    buttons: {
      connectGmail: 'Connect Gmail',
      connectOutlook: 'Connect Outlook',
    },
    emptyState: {
      title: 'Get started by connecting your account',
      description: 'Ellie will automatically categorise your emails and create draft replies. Ellie never sends email without you checking it first.',
    },
    status: {
      lastSynced: 'Last synced: {{time}}',
    },
    actions: {
      disconnect: 'Disconnect',
    },
    messages: {
      disconnectConfirm: 'Are you sure you want to disconnect your {{provider}} account?',
      disconnectSuccess: '{{provider}} account disconnected successfully',
      errors: {
        loadFailed: 'Failed to load email accounts',
        disconnectFailed: 'Failed to disconnect {{provider}} account',
        connectFailed: 'Failed to connect {{provider}} account',
        authFailed: 'Failed to authenticate',
      },
    },
  },
  dashboard: {
    title: 'Dashboard',
    welcome: 'Welcome, {{name}}',
    stats: {
      usageStatistics: 'Usage Statistics',
      repliesToday: 'Replies Today',
      totalReplies: 'Total Replies',
      costToday: 'Cost Today',
      teamInformation: 'Team Information',
      plan: 'Plan',
      members: 'Members',
      role: 'Role',
      teamOwner: 'Team Owner',
      teamMember: 'Team Member',
      trainingStatus: 'Training Status',
      status: 'Status',
      messagesProcessed: 'Messages Processed',
      processingCost: 'Processing Cost',
      tokenUsage: 'Token Usage',
      promptTokens: 'Prompt Tokens',
      completionTokens: 'Completion Tokens',
      totalTokens: 'Total Tokens',
    },
  },
  roles: {
    title: 'Roles',
    addRole: 'Add Role',
    editRole: 'Edit Role',
    defaultRole: 'Default',
    setAsDefault: 'Set as default',
    clearDefault: 'Clear default role',
    deleteConfirm: 'Are you sure you want to delete this role?',
    messages: {
      fetchError: 'Failed to fetch roles',
      createError: 'Failed to create role',
      updateError: 'Failed to update role',
      deleteError: 'Failed to delete role',
      setDefaultSuccess: '{{name}} set as default role',
      clearDefaultSuccess: '{{name}} is no longer the default role',
    },
  },
  knowledge: {
    title: 'Knowledge Base',
    websiteTraining: {
      title: 'Train Ellie from a website',
    },
  },
  team: {
    title: 'Team Management',
    addMember: {
      title: 'Add Team Member',
      button: 'Add Member',
    },
    inviteLink: {
      title: 'Team Invite Link',
      description: 'Share this link with people you want to invite to your team',
      copied: 'Invite link copied to clipboard',
    },
    stats: {
      title: 'Team Statistics',
      totalMembers: 'Total Members',
      allowedMembers: 'Allowed Members',
      availableSlots: 'Available Slots',
    },
    member: {
      joined: 'Joined: {{date}}',
      ownerLabel: 'Owner',
      ownerCannotBeRemoved: 'Team owner cannot be removed',
      removeConfirm: 'Are you sure you want to remove {{name}} from the team?',
    },
    messages: {
      fetchError: 'Failed to fetch team data',
      addSuccess: 'Team member added successfully',
      addError: 'Failed to add team member',
      removeSuccess: 'Team member removed successfully',
      removeError: 'Failed to remove team member',
    },
  },
  settings: {
    title: 'Settings',
  },
};

export default translations; 