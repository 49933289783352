import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  Button,
  Card,
  CardContent,
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  IconButton,
  Tooltip,
  CircularProgress,
  Chip,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import StarIcon from '@mui/icons-material/Star';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import { Role, rolesApi, CreateRoleRequest } from '../services/api';
import axios from 'axios';
import { useTranslation } from 'react-i18next';

interface RoleFormData {
  name: string;
  description: string;
  prompt: string;
}

export const RolesPage: React.FC = () => {
  const { t } = useTranslation();
  const [roles, setRoles] = useState<Role[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [editingRole, setEditingRole] = useState<Role | null>(null);
  const [formData, setFormData] = useState<RoleFormData>({
    name: '',
    description: '',
    prompt: '',
  });
  const [successMessage, setSuccessMessage] = useState<string | null>(null);

  const fetchRoles = async () => {
    try {
      console.log('Fetching roles...');
      const response = await rolesApi.getRoles();
      console.log('Roles response:', response);
      setRoles(response);
      setError(null);
    } catch (err) {
      console.error('Error fetching roles:', err);
      setError(t('roles.messages.fetchError'));
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchRoles();
  }, []);

  const handleOpenDialog = (role?: Role) => {
    if (role) {
      setEditingRole(role);
      setFormData({
        name: role.name,
        description: role.description,
        prompt: role.prompt,
      });
    } else {
      setEditingRole(null);
      setFormData({
        name: '',
        description: '',
        prompt: '',
      });
    }
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setEditingRole(null);
    setFormData({
      name: '',
      description: '',
      prompt: '',
    });
  };

  const handleSubmit = async () => {
    try {
      console.log('Submitting role:', formData);
      if (editingRole) {
        const response = await rolesApi.updateRole(editingRole.uuid, formData);
        console.log('Update response:', response);
      } else {
        const createRequest: CreateRoleRequest = {
          ...formData,
          isDefault: false,
        };
        const response = await rolesApi.createRole(createRequest);
        console.log('Create response:', response);
      }
      await fetchRoles();
      handleCloseDialog();
    } catch (err) {
      console.error('Error submitting role:', err);
      if (axios.isAxiosError(err) && err.response?.data?.error) {
        setError(err.response.data.error);
      } else {
        setError(t(editingRole ? 'roles.messages.updateError' : 'roles.messages.createError'));
      }
    }
  };

  const handleDelete = async (role: Role) => {
    if (window.confirm(t('roles.deleteConfirm'))) {
      try {
        console.log('Deleting role:', role.uuid);
        const response = await rolesApi.deleteRole(role.uuid);
        console.log('Delete response:', response);
        await fetchRoles();
      } catch (err) {
        console.error('Error deleting role:', err);
        setError(t('roles.messages.deleteError'));
      }
    }
  };

  const handleSetDefault = async (role: Role) => {
    try {
      if (role.isDefault) {
        await rolesApi.clearDefault(role.uuid);
        setSuccessMessage(t('roles.messages.clearDefaultSuccess', { name: role.name }));
      } else {
        const currentDefault = roles.find(r => r.isDefault);
        await rolesApi.setDefault(role.uuid, currentDefault?.uuid || null);
        setSuccessMessage(t('roles.messages.setDefaultSuccess', { name: role.name }));
      }
      await fetchRoles();
    } catch (err) {
      console.error('Error updating default role:', err);
      if (axios.isAxiosError(err) && err.response?.data?.error) {
        setError(err.response.data.error);
      } else {
        setError(t('roles.messages.updateError'));
      }
    }
  };

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="200px">
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box>
      <Box display="flex" justifyContent="space-between" alignItems="center" mb={3}>
        <Typography variant="h4">{t('roles.title')}</Typography>
        <Button
          variant="contained"
          color="primary"
          startIcon={<AddIcon />}
          onClick={() => handleOpenDialog()}
        >
          {t('roles.addRole')}
        </Button>
      </Box>

      {error && (
        <Typography color="error" mb={3}>
          {error}
        </Typography>
      )}

      <Grid container spacing={3}>
        {roles.map((role) => (
          <Grid item xs={12} md={6} lg={4} key={role.uuid}>
            <Card>
              <CardContent>
                <Box display="flex" justifyContent="space-between" alignItems="flex-start">
                  <Box>
                    <Typography variant="h6" gutterBottom>
                      {role.name}
                      {role.isDefault && (
                        <Chip
                          label={t('roles.defaultRole')}
                          size="small"
                          color="secondary"
                          sx={{ ml: 1 }}
                        />
                      )}
                    </Typography>
                    <Typography color="textSecondary" gutterBottom>
                      {role.description}
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
                      {t('common.labels.prompt')}: {role.prompt}
                    </Typography>
                  </Box>
                  <Box>
                    <Tooltip title={role.isDefault ? t('roles.clearDefault') : t('roles.setAsDefault')}>
                      <IconButton 
                        size="small" 
                        onClick={() => handleSetDefault(role)}
                        color={role.isDefault ? "secondary" : "default"}
                        sx={{ mr: 1 }}
                      >
                        {role.isDefault ? <StarIcon /> : <StarBorderIcon />}
                      </IconButton>
                    </Tooltip>
                    <Tooltip title={t('common.actions.edit')}>
                      <IconButton size="small" onClick={() => handleOpenDialog(role)}>
                        <EditIcon />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title={t('common.actions.delete')}>
                      <IconButton 
                        size="small" 
                        onClick={() => handleDelete(role)}
                        disabled={role.isDefault}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </Tooltip>
                  </Box>
                </Box>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>

      <Dialog open={openDialog} onClose={handleCloseDialog} maxWidth="sm" fullWidth>
        <DialogTitle>{editingRole ? t('roles.editRole') : t('roles.addRole')}</DialogTitle>
        <DialogContent>
          <Box mt={2}>
            <TextField
              fullWidth
              label={t('common.labels.name')}
              value={formData.name}
              onChange={(e) => setFormData({ ...formData, name: e.target.value })}
              margin="normal"
            />
            <TextField
              fullWidth
              label={t('common.labels.description')}
              value={formData.description}
              onChange={(e) => setFormData({ ...formData, description: e.target.value })}
              margin="normal"
            />
            <TextField
              fullWidth
              label={t('common.labels.prompt')}
              value={formData.prompt}
              onChange={(e) => setFormData({ ...formData, prompt: e.target.value })}
              margin="normal"
              multiline
              rows={4}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog}>{t('common.cancel')}</Button>
          <Button onClick={handleSubmit} color="primary" variant="contained">
            {editingRole ? t('common.actions.edit') : t('common.actions.add')}
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}; 