import React, { useState, useEffect } from 'react';
import { Box, Typography, Paper, Grid, CircularProgress, Alert, LinearProgress } from '@mui/material';
import { User, userApi } from '../services/api';
import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';

export const DashboardPage: React.FC = () => {
  const { t } = useTranslation();
  const [user, setUser] = useState<User | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const userData = await userApi.getCurrentUser();
        setUser(userData);
      } catch (err) {
        console.error('Error fetching user data:', err);
        setError(t('common.error'));
      } finally {
        setLoading(false);
      }
    };

    fetchUser();
  }, [t]);

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="200px">
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Alert severity="error" sx={{ mt: 2 }}>
        {error}
      </Alert>
    );
  }

  if (!user) {
    return null;
  }

  // Get usage values with defaults
  const {
    repliesGeneratedToday = 0,
    generatedRepliesLimit = 0,
    totalRepliesGenerated = 0,
  } = user.usage || {};

  // Get team values with defaults
  const {
    members = 0,
    membersRemaining = 0,
  } = user.team || {};

  // Get training values with defaults
  const {
    status = 'pending',
    error: trainingError = null,
    processingMessages = 0,
    processingCost = 0,
  } = user.training || {};

  // Onboarding steps - this would ideally come from the API
  const onboardingSteps = [
    { id: 'configure_role', label: 'Configure role', completed: true }, // Since roles are configured at team level, we'll assume it's always configured
    { id: 'add_knowledge', label: 'Add Knowledge', completed: false }, // Update based on actual data
    { id: 'invite_team', label: 'Invite team', completed: members > 1 },
    { id: 'generate_reply', label: 'Generate reply', completed: totalRepliesGenerated > 0 },
    { id: 'connect_account', label: 'Connect account', completed: user.billing?.plan !== 'Free' }
  ];

  const completedSteps = onboardingSteps.filter(step => step.completed).length;
  const progress = (completedSteps / onboardingSteps.length) * 100;

  return (
    <Box>
      <Typography variant="h4" gutterBottom>
        {t('dashboard.title')}
      </Typography>

      <Grid container spacing={3}>
        {/* Onboarding Progress */}
        <Grid item xs={12}>
          <Paper sx={{ p: 3 }}>
            <Typography variant="h6" gutterBottom>
              Onboarding Progress
            </Typography>
            <Box sx={{ mb: 2 }}>
              <LinearProgress 
                variant="determinate" 
                value={progress} 
                sx={{ height: 10, borderRadius: 5 }}
              />
              <Typography variant="body2" color="textSecondary" sx={{ mt: 1 }}>
                {completedSteps} of {onboardingSteps.length} steps completed
              </Typography>
            </Box>
            <Grid container spacing={2}>
              {onboardingSteps.map((step) => (
                <Grid item xs={12} sm={6} md={4} key={step.id}>
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                    {step.completed ? (
                      <CheckCircleIcon color="success" />
                    ) : (
                      <RadioButtonUncheckedIcon color="disabled" />
                    )}
                    <Typography
                      variant="body1"
                      color={step.completed ? 'textPrimary' : 'textSecondary'}
                    >
                      {step.label}
                    </Typography>
                  </Box>
                </Grid>
              ))}
            </Grid>
          </Paper>
        </Grid>

        {/* Usage Stats */}
        <Grid item xs={12} md={6}>
          <Paper sx={{ p: 3 }}>
            <Typography variant="h6" gutterBottom>
              {t('dashboard.stats.usageStatistics')}
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Typography color="textSecondary">{t('dashboard.stats.repliesToday')}</Typography>
                <Typography variant="h4">
                  {repliesGeneratedToday} / {generatedRepliesLimit}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography color="textSecondary">{t('dashboard.stats.totalReplies')}</Typography>
                <Typography variant="h4">{totalRepliesGenerated}</Typography>
              </Grid>
            </Grid>
          </Paper>
        </Grid>

        {/* Team Info */}
        <Grid item xs={12} md={6}>
          <Paper sx={{ p: 3 }}>
            <Typography variant="h6" gutterBottom>
              {t('dashboard.stats.teamInformation')}
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Typography color="textSecondary">{t('dashboard.stats.plan')}</Typography>
                <Typography variant="h6">{user.billing?.plan || 'Free'}</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography color="textSecondary">{t('dashboard.stats.members')}</Typography>
                <Typography variant="h6">
                  {members} / {members + membersRemaining}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography color="textSecondary">{t('dashboard.stats.role')}</Typography>
                <Typography variant="h6">
                  {user.isTeamOwner ? t('dashboard.stats.teamOwner') : t('dashboard.stats.teamMember')}
                </Typography>
              </Grid>
            </Grid>
          </Paper>
        </Grid>

        {/* Training Status */}
        <Grid item xs={12} md={6}>
          <Paper sx={{ p: 3 }}>
            <Typography variant="h6" gutterBottom>
              {t('dashboard.stats.trainingStatus')}
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography color="textSecondary">{t('dashboard.stats.status')}</Typography>
                <Typography variant="h6" color={status === 'failed' ? 'error' : 'inherit'}>
                  {status.charAt(0).toUpperCase() + status.slice(1)}
                </Typography>
              </Grid>
              {trainingError && (
                <Grid item xs={12}>
                  <Typography color="error" variant="body2">
                    {trainingError}
                  </Typography>
                </Grid>
              )}
              <Grid item xs={6}>
                <Typography color="textSecondary">{t('dashboard.stats.messagesProcessed')}</Typography>
                <Typography variant="h6">{processingMessages}</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography color="textSecondary">{t('dashboard.stats.processingCost')}</Typography>
                <Typography variant="h6">${processingCost.toFixed(2)}</Typography>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
}; 