import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Button,
  Container,
  Paper,
  Typography,
  CircularProgress,
  TextField,
  Divider,
  Alert,
  Link,
} from '@mui/material';
import GoogleIcon from '@mui/icons-material/Google';
import MicrosoftIcon from '@mui/icons-material/Window';
import { useTranslation } from 'react-i18next';
import { useAuth } from '../contexts/AuthContext';
import { googleAuthApi, microsoftAuthApi } from '../services/api';

interface LoginFormData {
  email: string;
  password: string;
}

export const LoginPage: React.FC = () => {
  const { t } = useTranslation();
  const { login, loginWithGoogle, loginWithMicrosoft, error: authError } = useAuth();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [showRegister, setShowRegister] = useState(false);
  const [isInitializing, setIsInitializing] = useState(true);
  const [formData, setFormData] = useState<LoginFormData>({
    email: '',
    password: '',
  });
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const initializeAuth = async () => {
      setIsInitializing(true);
      try {
        await Promise.all([
          googleAuthApi.initialize(process.env.REACT_APP_GOOGLE_CLIENT_ID as string),
          microsoftAuthApi.initialize()
        ]);
      } catch (err) {
        console.error('Failed to initialize auth:', err);
        setError(t('auth.login.errors.initializationFailed'));
      } finally {
        setIsInitializing(false);
      }
    };
    
    initializeAuth();
  }, [t]);

  const handleEmailLogin = async (e: React.FormEvent) => {
    e.preventDefault();
    if (isInitializing) return;
    
    setError(null);
    setIsLoading(true);
    try {
      await login(formData.email, formData.password);
      navigate('/dashboard');
    } catch (err) {
      console.error('Login failed:', err);
      setError(t('auth.login.errors.invalidCredentials'));
    } finally {
      setIsLoading(false);
    }
  };

  const handleGoogleLogin = async () => {
    if (isInitializing) return;
    
    setError(null);
    setIsLoading(true);
    try {
      const accessToken = await googleAuthApi.signIn();
      await loginWithGoogle(accessToken);
      navigate('/dashboard');
    } catch (err) {
      console.error('Google login failed:', err);
      setError(t('auth.login.errors.googleFailed'));
    } finally {
      setIsLoading(false);
    }
  };

  const handleMicrosoftLogin = async () => {
    if (isInitializing) return;
    
    setError(null);
    setIsLoading(true);
    try {
      const accessToken = await microsoftAuthApi.signIn();
      if (accessToken) {
        await loginWithMicrosoft(accessToken);
        navigate('/dashboard');
      }
    } catch (err) {
      console.error('Microsoft login failed:', err);
      if (err instanceof Error) {
        if (err.message.includes('popup_window_error')) {
          setError(t('auth.login.errors.popupBlocked'));
        } else if (err.message.includes('user_cancelled') || err.message.includes('account_selection_failed')) {
          setError(t('auth.login.errors.accountSelectionFailed'));
        } else {
          setError(t('auth.login.errors.microsoftFailed'));
        }
      } else {
        setError(t('auth.login.errors.microsoftFailed'));
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Box
      sx={{
        minHeight: '100vh',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: 'background.default',
        backgroundImage: 'radial-gradient(circle at 50% 0%, rgba(147, 51, 234, 0.15), rgba(0, 0, 0, 0) 50%)',
      }}
    >
      <Container maxWidth="sm">
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            textAlign: 'center',
            mb: 4,
          }}
        >
          <Typography
            variant="h4"
            component="h1"
            gutterBottom
            sx={{
              fontWeight: 700,
              background: 'linear-gradient(to right, #9333EA, #4F46E5)',
              WebkitBackgroundClip: 'text',
              WebkitTextFillColor: 'transparent',
            }}
          >
            {t('auth.login.title')}
          </Typography>
          <Typography variant="body1" color="text.secondary" sx={{ mb: 4 }}>
            {t('auth.login.subtitle')}
          </Typography>
        </Box>

        <Paper
          elevation={0}
          sx={{
            p: 4,
            borderRadius: 3,
            border: '1px solid',
            borderColor: 'divider',
            backgroundColor: 'background.paper',
          }}
        >
          {(error || authError) && (
            <Alert severity="error" sx={{ mb: 3 }}>
              {error || authError}
            </Alert>
          )}

          <form onSubmit={handleEmailLogin}>
            <TextField
              fullWidth
              label={t('auth.login.email')}
              type="email"
              value={formData.email}
              onChange={(e) => setFormData({ ...formData, email: e.target.value })}
              margin="normal"
              required
              disabled={isLoading || isInitializing}
            />
            <TextField
              fullWidth
              label={t('auth.login.password')}
              type="password"
              value={formData.password}
              onChange={(e) => setFormData({ ...formData, password: e.target.value })}
              margin="normal"
              required
              disabled={isLoading || isInitializing}
            />
            <Button
              fullWidth
              type="submit"
              variant="contained"
              size="large"
              disabled={isLoading || isInitializing}
              sx={{ mt: 2 }}
            >
              {isLoading ? <CircularProgress size={24} /> : t('auth.login.signIn')}
            </Button>
          </form>

          <Box sx={{ my: 3 }}>
            <Divider>
              <Typography variant="body2" color="text.secondary">
                {t('auth.login.or')}
              </Typography>
            </Divider>
          </Box>

          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
            <Button
              fullWidth
              variant="outlined"
              size="large"
              startIcon={<GoogleIcon />}
              onClick={handleGoogleLogin}
              disabled={isLoading || isInitializing}
              sx={{
                borderColor: 'divider',
                '&:hover': {
                  borderColor: 'primary.main',
                  backgroundColor: 'rgba(147, 51, 234, 0.08)',
                },
              }}
            >
              {isInitializing ? t('auth.login.initializing') : t('auth.login.continueWithGoogle')}
            </Button>
            <Button
              fullWidth
              variant="outlined"
              size="large"
              startIcon={<MicrosoftIcon />}
              onClick={handleMicrosoftLogin}
              disabled={isLoading || isInitializing}
              sx={{
                borderColor: 'divider',
                '&:hover': {
                  borderColor: 'primary.main',
                  backgroundColor: 'rgba(147, 51, 234, 0.08)',
                },
              }}
            >
              {isInitializing ? t('auth.login.initializing') : t('auth.login.continueWithMicrosoft')}
            </Button>
          </Box>

          <Box sx={{ mt: 3, textAlign: 'center' }}>
            <Typography variant="body2" color="text.secondary">
              {t('auth.login.noAccount')}{' '}
              <Link
                component="button"
                variant="body2"
                onClick={() => setShowRegister(true)}
                sx={{ textDecoration: 'none' }}
              >
                {t('auth.login.signUp')}
              </Link>
            </Typography>
          </Box>
        </Paper>
      </Container>
    </Box>
  );
}; 