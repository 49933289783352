import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  Button,
  Card,
  CardContent,
  Grid,
  IconButton,
  Tooltip,
  CircularProgress,
  Alert,
  Chip,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import SyncIcon from '@mui/icons-material/Sync';
import GoogleIcon from '@mui/icons-material/Google';
import MicrosoftIcon from '@mui/icons-material/Window';
import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { EmailAccount, EmailAccountStatus, EmailProvider, emailApi } from '../services/api';
import axios from 'axios';

export const AccountsPage: React.FC = () => {
  const { t } = useTranslation();
  const [accounts, setAccounts] = useState<EmailAccount[]>([]);
  const [accountStatuses, setAccountStatuses] = useState<Record<string, EmailAccountStatus>>({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [successMessage, setSuccessMessage] = useState<string | null>(null);

  const fetchAccounts = async () => {
    try {
      const accountsData = await emailApi.getAccounts();
      setAccounts(accountsData);
      
      // Fetch status for each account
      const statuses: Record<string, EmailAccountStatus> = {};
      for (const account of accountsData) {
        try {
          const status = await emailApi.getAccountStatus(account.provider);
          statuses[account.provider] = status;
        } catch (error) {
          console.error(`Error fetching status for ${account.provider}:`, error);
        }
      }
      setAccountStatuses(statuses);
      setError(null);
    } catch (error) {
      console.error('Error fetching accounts:', error);
      if (axios.isAxiosError(error) && error.response?.data?.error) {
        setError(error.response.data.error);
      } else {
        setError(t('accounts.messages.errors.loadFailed'));
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchAccounts();
  }, []);

  const handleDisconnect = async (provider: EmailProvider) => {
    if (window.confirm(t('accounts.messages.disconnectConfirm', { provider: provider }))) {
      try {
        await emailApi.disconnectAccount(provider);
        await fetchAccounts();
        setSuccessMessage(t('accounts.messages.disconnectSuccess', { provider: provider }));
      } catch (error) {
        console.error('Error disconnecting account:', error);
        if (axios.isAxiosError(error) && error.response?.data?.error) {
          setError(error.response.data.error);
        } else {
          setError(t('accounts.messages.errors.disconnectFailed', { provider: provider }));
        }
      }
    }
  };

  const handleConnect = async (provider: EmailProvider) => {
    try {
      const { authUrl } = await emailApi.getAuthUrl(provider);
      const authWindow = window.open(authUrl, '_blank', 'width=600,height=800');
      
      // Poll for window close
      const pollTimer = setInterval(() => {
        if (authWindow?.closed) {
          clearInterval(pollTimer);
          fetchAccounts();
        }
      }, 500);
    } catch (error) {
      console.error('Error getting auth URL:', error);
      if (axios.isAxiosError(error) && error.response?.data?.error) {
        setError(error.response.data.error);
      } else {
        setError(t('accounts.messages.errors.connectFailed', { provider: provider }));
      }
    }
  };

  const getStatusColor = (status: string) => {
    switch (status) {
      case 'success':
        return 'success';
      case 'error':
        return 'error';
      case 'syncing':
        return 'warning';
      default:
        return 'default';
    }
  };

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="200px">
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box>
      <Box display="flex" justifyContent="space-between" alignItems="center" mb={3}>
        <Typography variant="h4">{t('accounts.title')}</Typography>
        <Box>
          <Button
            variant="contained"
            color="primary"
            startIcon={<GoogleIcon />}
            sx={{ mr: 2 }}
            disabled={accounts.some(a => a.provider === 'gmail')}
            onClick={() => handleConnect('gmail')}
          >
            {t('accounts.buttons.connectGmail')}
          </Button>
          <Button
            variant="contained"
            color="primary"
            startIcon={<MicrosoftIcon />}
            disabled={accounts.some(a => a.provider === 'outlook')}
            onClick={() => handleConnect('outlook')}
          >
            {t('accounts.buttons.connectOutlook')}
          </Button>
        </Box>
      </Box>

      {error && (
        <Alert severity="error" sx={{ mb: 3 }} onClose={() => setError(null)}>
          {error}
        </Alert>
      )}

      {successMessage && (
        <Alert severity="success" sx={{ mb: 3 }} onClose={() => setSuccessMessage(null)}>
          {successMessage}
        </Alert>
      )}

      <Grid container spacing={3}>
        {accounts.map((account) => {
          const status = accountStatuses[account.provider];
          return (
            <Grid item xs={12} md={6} key={account.provider}>
              <Card>
                <CardContent>
                  <Box display="flex" justifyContent="space-between" alignItems="flex-start">
                    <Box>
                      <Typography variant="h6" gutterBottom>
                        {account.provider.charAt(0).toUpperCase() + account.provider.slice(1)}
                      </Typography>
                      <Typography color="textSecondary">{account.email}</Typography>
                      {status && (
                        <>
                          <Box display="flex" alignItems="center" mt={1}>
                            <Chip
                              label={status.syncStatus}
                              color={getStatusColor(status.syncStatus)}
                              size="small"
                              sx={{ mr: 1 }}
                            />
                            {status.lastSyncedAt && (
                              <Typography variant="body2" color="textSecondary">
                                {t('accounts.status.lastSynced', {
                                  time: format(new Date(status.lastSyncedAt), 'PPp')
                                })}
                              </Typography>
                            )}
                          </Box>
                          {status.lastSyncError && (
                            <Typography color="error" variant="body2" sx={{ mt: 1 }}>
                              {status.lastSyncError}
                            </Typography>
                          )}
                        </>
                      )}
                    </Box>
                    <Box>
                      <Tooltip title={t('accounts.actions.disconnect')}>
                        <IconButton
                          size="small"
                          onClick={() => handleDisconnect(account.provider)}
                          color="error"
                        >
                          <DeleteIcon />
                        </IconButton>
                      </Tooltip>
                    </Box>
                  </Box>
                </CardContent>
              </Card>
            </Grid>
          )
        })}
      </Grid>

      {accounts.length === 0 && !loading && (
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          minHeight="60vh"
          textAlign="center"
          px={4}
        >
          <Typography variant="h5" gutterBottom color="text.primary" sx={{ mb: 3 }}>
            {t('accounts.emptyState.title')}
          </Typography>
          <Typography color="text.secondary" sx={{ mb: 4, maxWidth: 600 }}>
            {t('accounts.emptyState.description')}
          </Typography>
          <Box display="flex" gap={2}>
            <Button
              variant="contained"
              color="primary"
              size="large"
              startIcon={<GoogleIcon />}
              onClick={() => handleConnect('gmail')}
            >
              {t('accounts.buttons.connectGmail')}
            </Button>
            <Button
              variant="contained"
              color="primary"
              size="large"
              startIcon={<MicrosoftIcon />}
              onClick={() => handleConnect('outlook')}
            >
              {t('accounts.buttons.connectOutlook')}
            </Button>
          </Box>
        </Box>
      )}
    </Box>
  );
}; 