import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  Button,
  Card,
  CardContent,
  Grid,
  IconButton,
  Tooltip,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Alert,
  Chip,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { TeamMember, TeamStats, teamApi, userApi } from '../services/api';
import { format, parseISO } from 'date-fns';
import axios from 'axios';
import { useTranslation } from 'react-i18next';

export const TeamPage: React.FC = () => {
  const { t } = useTranslation();
  const [members, setMembers] = useState<TeamMember[]>([]);
  const [stats, setStats] = useState<TeamStats | null>(null);
  const [teamUuid, setTeamUuid] = useState<string | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [formData, setFormData] = useState({ email: '', name: '' });
  const [successMessage, setSuccessMessage] = useState<string | null>(null);

  const fetchData = async () => {
    try {
      const [members, stats, user] = await Promise.all([
        teamApi.getMembers(),
        teamApi.getStats(),
        userApi.getCurrentUser(),
      ]);
      setMembers(members);
      setStats(stats);
      setTeamUuid(user.teamUuid);
      setError(null);
    } catch (err) {
      console.error('Error fetching team data:', err);
      if (axios.isAxiosError(err) && err.response?.data?.error) {
        setError(err.response.data.error);
      } else {
        setError(t('team.messages.fetchError'));
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleCopyInvite = () => {
    if (teamUuid) {
      const inviteUrl = `https://tryellie.com/join-team?team=${teamUuid}`;
      navigator.clipboard.writeText(inviteUrl);
      setSuccessMessage(t('team.inviteLink.copied'));
    }
  };

  const handleAddMember = async () => {
    try {
      await teamApi.addMember(formData);
      await fetchData();
      setOpenDialog(false);
      setFormData({ email: '', name: '' });
      setSuccessMessage(t('team.messages.addSuccess'));
    } catch (err) {
      console.error('Error adding member:', err);
      if (axios.isAxiosError(err) && err.response?.data?.error) {
        setError(err.response.data.error);
      } else {
        setError(t('team.messages.addError'));
      }
      setOpenDialog(false);
      setFormData({ email: '', name: '' });
    }
  };

  const handleRemoveMember = async (member: TeamMember) => {
    if (member.isTeamOwner) {
      setError(t('team.member.ownerCannotBeRemoved'));
      return;
    }
    
    if (window.confirm(t('team.member.removeConfirm', { name: member.name }))) {
      try {
        await teamApi.removeMember(member.uuid);
        await fetchData();
        setSuccessMessage(t('team.messages.removeSuccess'));
      } catch (err) {
        console.error('Error removing member:', err);
        if (axios.isAxiosError(err) && err.response?.data?.error) {
          setError(err.response.data.error);
        } else {
          setError(t('team.messages.removeError'));
        }
      }
    }
  };

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="200px">
        <CircularProgress />
      </Box>
    );
  }

  const inviteUrl = teamUuid ? `https://tryellie.com/join-team?team=${teamUuid}` : '';

  return (
    <Box>
      <Box display="flex" justifyContent="space-between" alignItems="center" mb={3}>
        <Typography variant="h4">{t('team.title')}</Typography>
        <Button
          variant="contained"
          color="primary"
          startIcon={<AddIcon />}
          onClick={() => setOpenDialog(true)}
          disabled={stats?.availableSlots === 0}
        >
          {t('team.addMember.button')}
        </Button>
      </Box>

      {error && (
        <Alert severity="error" sx={{ mb: 3 }} onClose={() => setError(null)}>
          {error}
        </Alert>
      )}

      {successMessage && (
        <Alert severity="success" sx={{ mb: 3 }} onClose={() => setSuccessMessage(null)}>
          {successMessage}
        </Alert>
      )}

      {stats && (
        <Card sx={{ mb: 3 }}>
          <CardContent>
            <Typography variant="h6" gutterBottom>
              {t('team.stats.title')}
            </Typography>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={4}>
                <Typography color="textSecondary">{t('team.stats.totalMembers')}</Typography>
                <Typography variant="h4">{stats.totalMembers}</Typography>
              </Grid>
              <Grid item xs={12} sm={4}>
                <Typography color="textSecondary">{t('team.stats.allowedMembers')}</Typography>
                <Typography variant="h4">{stats.allowedMembers}</Typography>
              </Grid>
              <Grid item xs={12} sm={4}>
                <Typography color="textSecondary">{t('team.stats.availableSlots')}</Typography>
                <Typography variant="h4">{stats.availableSlots}</Typography>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      )}

      <Card sx={{ mb: 3 }}>
        <CardContent>
          <Box display="flex" justifyContent="space-between" alignItems="center">
            <Box>
              <Typography variant="h6" gutterBottom>
                {t('team.inviteLink.title')}
              </Typography>
              <Typography
                variant="body2"
                color="textSecondary"
                sx={{ wordBreak: 'break-all' }}
              >
                {inviteUrl}
              </Typography>
              <Typography variant="body2" color="textSecondary" sx={{ mt: 1 }}>
                {t('team.inviteLink.description')}
              </Typography>
            </Box>
            <Tooltip title={t('common.actions.copy')}>
              <IconButton onClick={handleCopyInvite} disabled={!teamUuid}>
                <ContentCopyIcon />
              </IconButton>
            </Tooltip>
          </Box>
        </CardContent>
      </Card>

      <Grid container spacing={3}>
        {members.map((member) => (
          <Grid item xs={12} md={6} lg={4} key={member.uuid}>
            <Card>
              <CardContent>
                <Box display="flex" justifyContent="space-between" alignItems="flex-start">
                  <Box>
                    <Typography variant="h6" gutterBottom>
                      {member.name}
                      {member.isTeamOwner && (
                        <Chip
                          label={t('team.member.ownerLabel')}
                          size="small"
                          color="primary"
                          sx={{ ml: 1 }}
                        />
                      )}
                    </Typography>
                    <Typography color="textSecondary">{member.email}</Typography>
                    {member.joinedAt && (
                      <Typography variant="body2" color="textSecondary" sx={{ mt: 1 }}>
                        {t('team.member.joined', { date: format(parseISO(member.joinedAt), 'PP') })}
                      </Typography>
                    )}
                  </Box>
                  <Tooltip title={member.isTeamOwner ? t('team.member.ownerCannotBeRemoved') : t('common.actions.remove')}>
                    <span>
                      <IconButton
                        size="small"
                        onClick={() => handleRemoveMember(member)}
                        color="error"
                        disabled={member.isTeamOwner}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </span>
                  </Tooltip>
                </Box>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>

      <Dialog open={openDialog} onClose={() => setOpenDialog(false)} maxWidth="sm" fullWidth>
        <DialogTitle>{t('team.addMember.title')}</DialogTitle>
        <DialogContent>
          <Box mt={2}>
            <TextField
              fullWidth
              label={t('common.labels.email')}
              type="email"
              value={formData.email}
              onChange={(e) => setFormData({ ...formData, email: e.target.value })}
              margin="normal"
            />
            <TextField
              fullWidth
              label={t('common.labels.name')}
              value={formData.name}
              onChange={(e) => setFormData({ ...formData, name: e.target.value })}
              margin="normal"
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDialog(false)}>{t('common.cancel')}</Button>
          <Button onClick={handleAddMember} color="primary" variant="contained">
            {t('common.actions.add')}
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}; 