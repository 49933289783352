import { Translations } from '../types';

const translations: Translations = {
  common: {
    loading: 'Cargando...',
    error: 'Error',
    success: 'Éxito',
    cancel: 'Cancelar',
    navigation: {
      dashboard: 'Panel',
      roles: 'Roles',
      knowledge: 'Conocimiento',
      accounts: 'Cuentas',
      team: 'Equipo',
      settings: 'Configuración',
      logout: 'Cerrar sesión',
    },
    header: {
      title: 'Panel de administración',
      switchTheme: 'Cambiar a modo {{mode}}',
    },
    labels: {
      name: 'Nombre',
      email: 'Correo electrónico',
      description: 'Descripción',
      prompt: 'Prompt',
      default: 'Predeterminado',
      owner: 'Propietario',
    },
    actions: {
      add: 'Agregar',
      edit: 'Editar',
      delete: 'Eliminar',
      confirm: '¿Está seguro?',
      copy: 'Copiar',
      remove: 'Eliminar',
    },
  },
  auth: {
    login: {
      title: 'Bienvenido a Ellie',
      subtitle: 'Inicie sesión para acceder a su panel',
      email: 'Correo electrónico',
      password: 'Contraseña',
      signIn: 'Iniciar sesión',
      or: 'O',
      continueWithGoogle: 'Continuar con Google',
      continueWithMicrosoft: 'Continuar con Microsoft',
      noAccount: '¿No tiene una cuenta?',
      signUp: 'Registrarse',
      initializing: 'Inicializando...',
      errors: {
        invalidCredentials: 'Correo electrónico o contraseña inválidos',
        googleFailed: 'Error al iniciar sesión con Google',
        microsoftFailed: 'Error al iniciar sesión con Microsoft',
        initializationFailed: 'Error al inicializar la autenticación',
        interactionInProgress: 'Otro intento de inicio de sesión está en curso. Por favor, espere un momento y vuelva a intentarlo.',
        popupBlocked: 'Se bloqueó la ventana emergente de inicio de sesión. Por favor, permita ventanas emergentes para este sitio.',
        redirectFailed: 'Error al redirigir a la página de inicio de sesión. Por favor, inténtelo de nuevo.',
        accountSelectionFailed: 'Error al seleccionar la cuenta. Por favor, inténtelo de nuevo.',
        tokenAcquisitionFailed: 'Error al obtener el token de autenticación. Por favor, inténtelo de nuevo.'
      },
      messages: {
        redirecting: 'Redirigiendo a la página de inicio de sesión...',
        authenticating: 'Autenticando...',
        loggingOut: 'Cerrando sesión...'
      }
    }
  },
  accounts: {
    title: 'Cuentas de correo',
    buttons: {
      connectGmail: 'Conectar Gmail',
      connectOutlook: 'Conectar Outlook',
    },
    emptyState: {
      title: 'Comience conectando su cuenta',
      description: 'Ellie categorizará automáticamente sus correos y creará borradores de respuestas. Ellie nunca envía correos sin su verificación.',
    },
    status: {
      lastSynced: 'Última sincronización: {{time}}',
    },
    actions: {
      disconnect: 'Desconectar',
    },
    messages: {
      disconnectConfirm: '¿Está seguro de que desea desconectar su cuenta de {{provider}}?',
      disconnectSuccess: 'Cuenta de {{provider}} desconectada exitosamente',
      errors: {
        loadFailed: 'Error al cargar las cuentas de correo',
        disconnectFailed: 'Error al desconectar la cuenta de {{provider}}',
        connectFailed: 'Error al conectar la cuenta de {{provider}}',
        authFailed: 'Error de autenticación',
      },
    },
  },
  dashboard: {
    title: 'Panel',
    welcome: 'Bienvenido, {{name}}',
    stats: {
      usageStatistics: 'Estadísticas de uso',
      repliesToday: 'Respuestas hoy',
      totalReplies: 'Total de respuestas',
      costToday: 'Costo hoy',
      teamInformation: 'Información del equipo',
      plan: 'Plan',
      members: 'Miembros',
      role: 'Rol',
      teamOwner: 'Propietario del equipo',
      teamMember: 'Miembro del equipo',
      trainingStatus: 'Estado del entrenamiento',
      status: 'Estado',
      messagesProcessed: 'Mensajes procesados',
      processingCost: 'Costo de procesamiento',
      tokenUsage: 'Uso de tokens',
      promptTokens: 'Tokens de prompt',
      completionTokens: 'Tokens de respuesta',
      totalTokens: 'Total de tokens',
    },
  },
  roles: {
    title: 'Roles',
    addRole: 'Agregar rol',
    editRole: 'Editar rol',
    defaultRole: 'Predeterminado',
    setAsDefault: 'Establecer como predeterminado',
    clearDefault: 'Quitar predeterminado',
    deleteConfirm: '¿Está seguro de que desea eliminar este rol?',
    messages: {
      fetchError: 'Error al cargar los roles',
      createError: 'Error al crear el rol',
      updateError: 'Error al actualizar el rol',
      deleteError: 'Error al eliminar el rol',
      setDefaultSuccess: '{{name}} es ahora el rol predeterminado',
      clearDefaultSuccess: '{{name}} ya no es el rol predeterminado',
    },
  },
  knowledge: {
    title: 'Base de conocimiento',
    websiteTraining: {
      title: 'Entrenar a Ellie desde un sitio web',
    },
  },
  team: {
    title: 'Gestión del equipo',
    addMember: {
      title: 'Agregar miembro',
      button: 'Agregar miembro',
    },
    inviteLink: {
      title: 'Enlace de invitación',
      description: 'Comparta este enlace con las personas que desea invitar a su equipo',
      copied: 'Enlace de invitación copiado',
    },
    stats: {
      title: 'Estadísticas del equipo',
      totalMembers: 'Total de miembros',
      allowedMembers: 'Miembros permitidos',
      availableSlots: 'Espacios disponibles',
    },
    member: {
      joined: 'Se unió: {{date}}',
      ownerLabel: 'Propietario',
      ownerCannotBeRemoved: 'El propietario del equipo no puede ser eliminado',
      removeConfirm: '¿Está seguro de que desea eliminar a {{name}} del equipo?',
    },
    messages: {
      fetchError: 'Error al cargar los datos del equipo',
      addSuccess: 'Miembro agregado exitosamente',
      addError: 'Error al agregar el miembro',
      removeSuccess: 'Miembro eliminado exitosamente',
      removeError: 'Error al eliminar el miembro',
    },
  },
  settings: {
    title: 'Configuración',
  },
};

export default translations; 