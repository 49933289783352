import { createTheme, ThemeOptions, Components, Theme } from '@mui/material/styles';

interface CustomThemeComponents {
  MuiListItemButton: {
    styleOverrides: {
      root: {
        borderRadius: number;
        margin: string;
        padding: string;
      };
    };
  };
}

const commonThemeOptions: Partial<ThemeOptions> & {
  components?: Partial<Components<Theme> & CustomThemeComponents>;
} = {
  shape: {
    borderRadius: 12,
  },
  typography: {
    fontFamily: '"Inter", "Helvetica", "Arial", sans-serif',
    h1: { fontWeight: 600 },
    h2: { fontWeight: 600 },
    h3: { fontWeight: 600 },
    h4: { fontWeight: 600 },
    h5: { fontWeight: 600 },
    h6: { fontWeight: 600 },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          borderRadius: 8,
          fontWeight: 500,
        },
        contained: {
          boxShadow: 'none',
          '&:hover': {
            boxShadow: 'none',
          },
        },
      },
    },
    MuiListItemButton: {
      styleOverrides: {
        root: {
          borderRadius: 8,
          margin: '4px 8px',
          padding: '8px 16px',
        },
      },
    },
  },
};

const listItemButtonBaseStyles = {
  borderRadius: 8,
  margin: '4px 8px',
  padding: '8px 16px',
};

export const darkTheme = createTheme({
  ...commonThemeOptions,
  palette: {
    mode: 'dark',
    primary: {
      main: '#9333EA',
      light: '#A855F7',
      dark: '#7E22CE',
    },
    secondary: {
      main: '#4F46E5',
      light: '#6366F1',
      dark: '#4338CA',
    },
    background: {
      default: '#0A0A0A',
      paper: '#18181B',
    },
    text: {
      primary: '#FFFFFF',
      secondary: '#A1A1AA',
    },
    divider: '#27272A',
  },
  components: {
    ...commonThemeOptions.components,
    MuiAppBar: {
      styleOverrides: {
        root: {
          backgroundColor: '#9333EA',
          backgroundImage: 'none',
          borderBottom: '1px solid #27272A',
          color: '#FFFFFF',
        },
      },
    },
    MuiDrawer: {
      styleOverrides: {
        paper: {
          width: 240,
          backgroundColor: '#18181B',
          borderRight: '1px solid #27272A',
        },
      },
    },
    MuiListItemButton: {
      styleOverrides: {
        root: {
          ...listItemButtonBaseStyles,
          '&.Mui-selected': {
            backgroundColor: '#27272A',
            '&:hover': {
              backgroundColor: '#27272A',
            },
          },
          '&:hover': {
            backgroundColor: '#27272A',
          },
        },
      },
    },
  },
});

export const lightTheme = createTheme({
  ...commonThemeOptions,
  palette: {
    mode: 'light',
    primary: {
      main: '#9333EA',
      light: '#A855F7',
      dark: '#7E22CE',
      contrastText: '#FFFFFF',
    },
    secondary: {
      main: '#4F46E5',
      light: '#6366F1',
      dark: '#4338CA',
      contrastText: '#FFFFFF',
    },
    background: {
      default: '#F9FAFB',
      paper: '#FFFFFF',
    },
    text: {
      primary: '#111827',
      secondary: '#6B7280',
    },
    divider: '#E5E7EB',
  },
  components: {
    ...commonThemeOptions.components,
    MuiAppBar: {
      styleOverrides: {
        root: {
          backgroundColor: '#9333EA',
          backgroundImage: 'none',
          borderBottom: '1px solid #E5E7EB',
          color: '#FFFFFF',
        },
      },
    },
    MuiDrawer: {
      styleOverrides: {
        paper: {
          width: 240,
          backgroundColor: '#FFFFFF',
          borderRight: '1px solid #E5E7EB',
        },
      },
    },
    MuiListItemButton: {
      styleOverrides: {
        root: {
          ...listItemButtonBaseStyles,
          color: '#111827',
          '&.Mui-selected': {
            backgroundColor: '#F3F4F6',
            '&:hover': {
              backgroundColor: '#F3F4F6',
            },
          },
          '&:hover': {
            backgroundColor: '#F3F4F6',
          },
        },
      },
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          color: '#6B7280',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          borderRadius: 8,
          fontWeight: 500,
        },
        contained: {
          color: '#FFFFFF',
          boxShadow: 'none',
          '&:hover': {
            boxShadow: 'none',
          },
        },
        outlined: {
          borderColor: '#E5E7EB',
          color: '#111827',
          '&:hover': {
            borderColor: '#9333EA',
            backgroundColor: 'rgba(147, 51, 234, 0.08)',
          },
        },
      },
    },
  },
}); 