import React, { useState, useEffect, useRef } from 'react';
import {
  Box,
  Typography,
  Button,
  Card,
  CardContent,
  Grid,
  IconButton,
  Tooltip,
  CircularProgress,
  Alert,
  LinearProgress,
  Chip,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Divider,
  Paper,
  Container,
  List,
  ListItem,
  ListItemText,
  Collapse,
} from '@mui/material';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import DeleteIcon from '@mui/icons-material/Delete';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import AddIcon from '@mui/icons-material/Add';
import LanguageIcon from '@mui/icons-material/Language';
import RefreshIcon from '@mui/icons-material/Refresh';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import axios from 'axios';
import { CorpusSummary, knowledgeApi, WebsiteTrainingRequest, ApiError } from '../services/api';
import { format } from 'date-fns';
import { DataGrid, GridColDef, GridRenderCellParams, GridValueFormatterParams } from '@mui/x-data-grid';

interface TrainingAttachmentLog {
  attachmentId: string;
  status: 'pending' | 'processed' | 'failed' | 'skipped';
  error?: string;
  reason?: string;
  contentType: string;
  processingCompletedAt?: string;
}

interface TrainingEmailLog {
  userId: string;
  emailId: string;
  receivedAt: string;
  subject: string;
  from: string;
  totalAttachments: number;
  validAttachments: number;
  attachments: TrainingAttachmentLog[];
}

function AttachmentDetails({ attachments }: { attachments: TrainingAttachmentLog[] }) {
  return (
    <Box sx={{ p: 2 }}>
      <Typography variant="subtitle2" sx={{ mb: 2 }}>
        Attachments
      </Typography>
      <Grid container spacing={2}>
        {attachments.map((attachment) => (
          <Grid item xs={12} key={attachment.attachmentId}>
            <Paper
              variant="outlined"
              sx={{ p: 2 }}
            >
              <Grid container spacing={2}>
                <Grid item xs={4}>
                  <Typography variant="body2" color="textSecondary">
                    Content Type:
                  </Typography>
                  <Typography variant="body2">
                    {attachment.contentType}
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography variant="body2" color="textSecondary">
                    Status:
                  </Typography>
                  <Chip
                    label={attachment.status}
                    color={
                      attachment.status === 'processed' ? 'success' :
                      attachment.status === 'failed' ? 'error' :
                      attachment.status === 'skipped' ? 'warning' : 'default'
                    }
                    size="small"
                  />
                </Grid>
                <Grid item xs={4}>
                  {attachment.processingCompletedAt && (
                    <>
                      <Typography variant="body2" color="textSecondary">
                        Processed At:
                      </Typography>
                      <Typography variant="body2">
                        {format(new Date(attachment.processingCompletedAt), 'PPp')}
                      </Typography>
                    </>
                  )}
                </Grid>
                {(attachment.error || attachment.reason) && (
                  <Grid item xs={12}>
                    <Typography variant="body2" color="error">
                      {attachment.error || attachment.reason}
                    </Typography>
                  </Grid>
                )}
              </Grid>
            </Paper>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
}

function CrawledUrlsList({ urls }: { urls: string[] }) {
  const [open, setOpen] = useState(false);

  return (
    <>
      <Box sx={{ mt: 1 }}>
        <Button
          size="small"
          onClick={() => setOpen(true)}
          startIcon={<KeyboardArrowDownIcon />}
        >
          View Crawled Pages ({urls.length})
        </Button>
      </Box>

      <Dialog 
        open={open} 
        onClose={() => setOpen(false)}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>
          Crawled Pages
          <Typography variant="body2" color="textSecondary" sx={{ mt: 1 }}>
            {urls.length} pages were crawled from this website
          </Typography>
        </DialogTitle>
        <DialogContent dividers>
          <List sx={{ 
            width: '100%',
            bgcolor: 'background.paper',
            '& .MuiListItem-root': {
              borderBottom: 1,
              borderColor: 'divider',
              '&:last-child': {
                borderBottom: 0
              }
            }
          }}>
            {urls.map((url, index) => (
              <ListItem 
                key={index}
                sx={{ py: 1 }}
              >
                <ListItemText
                  primary={url}
                  primaryTypographyProps={{
                    sx: { 
                      fontFamily: 'monospace',
                      fontSize: '0.875rem'
                    }
                  }}
                />
              </ListItem>
            ))}
          </List>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)}>Close</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export const KnowledgePage: React.FC = () => {
  const [summaries, setSummaries] = useState<CorpusSummary[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [successMessage, setSuccessMessage] = useState<string | null>(null);
  const [uploading, setUploading] = useState(false);
  const [trainingStatus, setTrainingStatus] = useState<'idle' | 'processing'>('idle');
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [openWebsiteDialog, setOpenWebsiteDialog] = useState(false);
  const [websiteUrl, setWebsiteUrl] = useState('');
  const [excludeUrls, setExcludeUrls] = useState<string[]>(['']);
  const [trainingEmails, setTrainingEmails] = useState<TrainingEmailLog[]>([]);
  const [loadingEmails, setLoadingEmails] = useState(false);
  const [trainingEmail, setTrainingEmail] = useState<string | null>(null);

  const fetchSummaries = async () => {
    try {
      console.log('Fetching corpus summaries...');
      const response = await knowledgeApi.getCorpusSummaries();
      console.log('Corpus summaries response:', response);
      setSummaries(response.data);
      setError(null);
    } catch (err) {
      console.error('Error fetching corpus summaries:', err);
      setError('Failed to fetch knowledge base summaries');
    } finally {
      setLoading(false);
    }
  };

  const fetchTrainingStatus = async () => {
    try {
      console.log('Fetching training status...');
      const response = await knowledgeApi.getTrainingStatus();
      console.log('Training status response:', response);
      setTrainingStatus(response.data.status === 'processing' ? 'processing' : 'idle');
      setTrainingEmail(response.data.trainingEmail);
    } catch (error: unknown) {
      console.error('Error fetching training status:', error);
      if (axios.isAxiosError(error) && error.response?.data) {
        const apiError = error.response.data as ApiError;
        console.error(apiError.error);
      }
    }
  };

  const fetchTrainingEmails = async () => {
    try {
      setLoadingEmails(true);
      const response = await knowledgeApi.getTrainingEmails();
      setTrainingEmails(response.data);
    } catch (error) {
      console.error('Error fetching training emails:', error);
      setError('Failed to fetch training emails');
    } finally {
      setLoadingEmails(false);
    }
  };

  useEffect(() => {
    fetchSummaries();
    fetchTrainingStatus();
  }, []);

  useEffect(() => {
    let interval: NodeJS.Timeout;
    if (trainingStatus === 'processing') {
      interval = setInterval(fetchTrainingStatus, 5000);
    }
    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [trainingStatus]);

  useEffect(() => {
    fetchTrainingEmails();
  }, []);

  const handleFileUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (!file) return;

    setUploading(true);
    try {
      console.log('Uploading file:', file.name);
      const response = await knowledgeApi.uploadCorpus(file);
      console.log('Upload response:', response);
      await fetchSummaries();
      setError(null);
    } catch (err) {
      console.error('Error uploading file:', err);
      setError('Failed to upload file');
    } finally {
      setUploading(false);
      if (fileInputRef.current) {
        fileInputRef.current.value = '';
      }
    }
  };

  const handleDelete = async (id: string) => {
    if (window.confirm('Are you sure you want to delete this file?')) {
      try {
        console.log('Deleting corpus:', id);
        await knowledgeApi.deleteCorpus(id);
        await fetchSummaries();
        setSuccessMessage('File deleted successfully');
        setError(null);
      } catch (error: unknown) {
        console.error('Error deleting corpus:', error);
        if (axios.isAxiosError(error) && error.response?.data) {
          const apiError = error.response.data as ApiError;
          setError(apiError.error);
        } else {
          setError('Failed to delete file');
        }
      }
    }
  };

  const getStatusColor = (status: string): 'success' | 'error' | 'warning' | 'info' => {
    switch (status) {
      case 'finished':
        return 'success';
      case 'error':
        return 'error';
      case 'crawling':
      case 'processing':
        return 'warning';
      default:
        return 'info';
    }
  };

  const handleAddExcludeUrl = () => {
    setExcludeUrls([...excludeUrls, '']);
  };

  const handleExcludeUrlChange = (index: number, value: string) => {
    const newExcludeUrls = [...excludeUrls];
    newExcludeUrls[index] = value;
    setExcludeUrls(newExcludeUrls);
  };

  const handleRemoveExcludeUrl = (index: number) => {
    const newExcludeUrls = excludeUrls.filter((_, i) => i !== index);
    setExcludeUrls(newExcludeUrls);
  };

  const handleWebsiteSubmit = async () => {
    try {
      const filteredExcludeUrls = excludeUrls.filter(url => url.trim() !== '');
      const request: WebsiteTrainingRequest = {
        url: websiteUrl,
        excludeUrls: filteredExcludeUrls.length > 0 ? filteredExcludeUrls : undefined,
      };
      await knowledgeApi.trainFromWebsite(request);
      setOpenWebsiteDialog(false);
      setWebsiteUrl('');
      setExcludeUrls(['']);
      await fetchSummaries();
      setSuccessMessage('Website training started successfully');
    } catch (error: unknown) {
      console.error('Error starting website training:', error);
      if (axios.isAxiosError(error) && error.response?.data) {
        const apiError = error.response.data as ApiError;
        setError(apiError.error);
      } else {
        setError('Failed to start website training');
      }
    }
  };

  const emailColumns: GridColDef<TrainingEmailLog>[] = [
    {
      field: 'receivedAt',
      headerName: 'Received',
      width: 180,
      valueFormatter: (params: GridValueFormatterParams) => {
        const value = params.value as string;
        return value ? format(new Date(value), 'PPp') : '';
      },
    },
    {
      field: 'subject',
      headerName: 'Subject',
      width: 300,
    },
    {
      field: 'from',
      headerName: 'From',
      width: 200,
    },
    {
      field: 'totalAttachments',
      headerName: 'Total Attachments',
      width: 150,
      align: 'center',
    },
    {
      field: 'validAttachments',
      headerName: 'Valid Attachments',
      width: 150,
      align: 'center',
    },
    {
      field: 'status',
      headerName: 'Status',
      width: 150,
      renderCell: (params: GridRenderCellParams<TrainingEmailLog>) => {
        const attachments = params.row.attachments || [];
        const allProcessed = attachments.every((a: TrainingAttachmentLog) => a.status === 'processed');
        const anyFailed = attachments.some((a: TrainingAttachmentLog) => a.status === 'failed');
        const allSkipped = attachments.every((a: TrainingAttachmentLog) => a.status === 'skipped');
        
        let color: 'success' | 'error' | 'warning' | 'info' = 'info';
        let text = 'Pending';
        
        if (allProcessed) {
          color = 'success';
          text = 'Processed';
        } else if (anyFailed) {
          color = 'error';
          text = 'Failed';
        } else if (allSkipped) {
          color = 'warning';
          text = 'Skipped';
        }
        
        return <Chip label={text} color={color} size="small" />;
      }
    }
  ];

  const summaryColumns: GridColDef<CorpusSummary>[] = [
    {
      field: 'type',
      headerName: 'Type',
      width: 100,
      renderCell: (params: GridRenderCellParams<CorpusSummary>) => (
        <Chip 
          label={params.row.type === 'website' ? 'Website' : 'File'} 
          size="small"
          color={params.row.type === 'website' ? 'primary' : 'secondary'}
        />
      ),
    },
    {
      field: 'name',
      headerName: 'Name',
      width: 400,
      renderCell: (params: GridRenderCellParams<CorpusSummary>) => (
        <Box>
          <Typography variant="body2">
            {params.row.type === 'website' ? params.row.websiteUrl : params.row.fileName}
          </Typography>
          {params.row.type === 'website' && params.row.urlsCrawled && (
            <CrawledUrlsList urls={params.row.urlsCrawled} />
          )}
        </Box>
      ),
    },
    {
      field: 'status',
      headerName: 'Status',
      width: 150,
      renderCell: (params: GridRenderCellParams<CorpusSummary>) => {
        const color = getStatusColor(params.row.status);
        return <Chip label={params.row.status} color={color} size="small" />;
      },
    },
    {
      field: 'urlsCrawled',
      headerName: 'Pages Crawled',
      width: 150,
      valueGetter: (params) => params.row.type === 'website' ? params.row.stats.urlsCrawled : null,
      renderCell: (params: GridRenderCellParams<CorpusSummary>) => (
        params.row.type === 'website' ? (
          <Typography variant="body2">
            {params.row.stats.urlsCrawled}
          </Typography>
        ) : null
      ),
    },
    {
      field: 'createdAt',
      headerName: 'Added',
      width: 180,
      valueGetter: (params) => params.row.meta.createdAt,
      valueFormatter: (params: GridValueFormatterParams) => {
        const value = params.value as string;
        return value ? format(new Date(value), 'PPp') : '';
      },
    },
    {
      field: 'actions',
      headerName: 'Actions',
      width: 100,
      sortable: false,
      renderCell: (params: GridRenderCellParams<CorpusSummary>) => (
        <Tooltip title="Delete">
          <IconButton
            size="small"
            onClick={() => handleDelete(params.row.uuid)}
            disabled={params.row.status === 'crawling' || params.row.status === 'processing'}
          >
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      ),
    },
  ];

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="200px">
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box>
      <Box display="flex" justifyContent="space-between" alignItems="center" mb={3}>
        <Typography variant="h4">Knowledge Base</Typography>
        <Box>
          <input
            type="file"
            ref={fileInputRef}
            onChange={handleFileUpload}
            style={{ display: 'none' }}
            accept=".txt,.pdf,.doc,.docx"
          />
          <Button
            variant="contained"
            color="primary"
            startIcon={<LanguageIcon />}
            onClick={() => setOpenWebsiteDialog(true)}
            sx={{ mr: 2 }}
          >
            Add Website
          </Button>
          <Button
            variant="contained"
            color="primary"
            startIcon={uploading ? <CircularProgress size={20} /> : <CloudUploadIcon />}
            onClick={() => fileInputRef.current?.click()}
            disabled={uploading || trainingStatus === 'processing'}
          >
            Upload File
          </Button>
        </Box>
      </Box>

      {error && (
        <Alert severity="error" sx={{ mb: 3 }} onClose={() => setError(null)}>
          {error}
        </Alert>
      )}

      {successMessage && (
        <Alert severity="success" sx={{ mb: 3 }} onClose={() => setSuccessMessage(null)}>
          {successMessage}
        </Alert>
      )}

      {trainingStatus === 'processing' && (
        <Alert severity="info" sx={{ mb: 3 }}>
          Training in progress...
          <LinearProgress sx={{ mt: 1 }} />
        </Alert>
      )}

      <Paper sx={{ height: 400, width: '100%', mb: 4 }}>
        <DataGrid<CorpusSummary>
          rows={summaries}
          columns={summaryColumns}
          getRowId={(row) => row.uuid}
          loading={loading}
          density="comfortable"
          disableRowSelectionOnClick
          sx={{
            '& .MuiDataGrid-cell:focus': {
              outline: 'none',
            },
          }}
          slots={{
            noRowsOverlay: () => (
              <Box display="flex" alignItems="center" justifyContent="center" height="100%">
                <Typography color="textSecondary">
                  No files uploaded yet. Upload files to start building your knowledge base.
                </Typography>
              </Box>
            ),
          }}
        />
      </Paper>

      <Divider sx={{ my: 4 }} />
      
      <Box sx={{ mb: 3 }}>
        <Typography variant="h5" gutterBottom>
          Email Training Data
        </Typography>
        
        {trainingEmail && (
          <Paper sx={{ p: 3, mb: 3 }}>
            <Box>
              <Typography variant="h6" gutterBottom>
                Training Email Address
                <Tooltip title="Learn how to use email training">
                  <IconButton
                    size="small"
                    component="a"
                    href="https://help.tryellie.com/getting-started/dc945YcaCg6LHS6Efo79Af/improve-ellie%E2%80%99s-replies/rJhhUn9A6bhe9KgabASR9X"
                    target="_blank"
                    sx={{ ml: 1 }}
                  >
                    <HelpOutlineIcon fontSize="small" />
                  </IconButton>
                </Tooltip>
              </Typography>
              <Typography variant="body2" color="textSecondary" paragraph>
                Forward your sent emails as attachments to train Ellie on your writing style.
              </Typography>
              <Box 
                sx={{ 
                  display: 'flex', 
                  alignItems: 'center',
                  bgcolor: 'grey.100',
                  p: 2,
                  borderRadius: 1,
                  maxWidth: 'fit-content'
                }}
              >
                <Typography
                  variant="body2"
                  sx={{ 
                    fontFamily: 'monospace',
                    mr: 1
                  }}
                >
                  {trainingEmail}
                </Typography>
                <Tooltip title="Copy email address">
                  <IconButton
                    size="small"
                    onClick={() => {
                      navigator.clipboard.writeText(trainingEmail);
                      setSuccessMessage('Training email copied to clipboard');
                    }}
                  >
                    <ContentCopyIcon fontSize="small" />
                  </IconButton>
                </Tooltip>
              </Box>
              <Box sx={{ mt: 2 }}>
                <Typography variant="body2" color="textSecondary">
                  1. Go to your email's "Sent" folder
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  2. Select emails that represent your writing style
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  3. Forward them as attachments to the address above
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  4. Wait a few minutes for the emails to be processed
                </Typography>
              </Box>
            </Box>
          </Paper>
        )}
        
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', mb: 2 }}>
          <Button
            startIcon={<RefreshIcon />}
            onClick={fetchTrainingEmails}
            disabled={loadingEmails}
          >
            Refresh
          </Button>
        </Box>

        <Paper sx={{ height: 400, width: '100%', mb: 4 }}>
          <DataGrid<TrainingEmailLog>
            rows={trainingEmails}
            columns={emailColumns}
            getRowId={(row: TrainingEmailLog) => row.emailId}
            loading={loadingEmails}
            density="comfortable"
            disableRowSelectionOnClick
            sx={{
              '& .MuiDataGrid-cell:focus': {
                outline: 'none',
              },
            }}
          />
        </Paper>
      </Box>

      <Dialog 
        open={openWebsiteDialog} 
        onClose={() => setOpenWebsiteDialog(false)}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>Train Ellie from a website</DialogTitle>
        <DialogContent>
          <Box mt={2}>
            <Typography gutterBottom>
              Enter a website for Ellie to learn from:
            </Typography>
            <TextField
              fullWidth
              placeholder="https://example.com"
              value={websiteUrl}
              onChange={(e) => setWebsiteUrl(e.target.value)}
              margin="normal"
            />
            <Typography color="textSecondary" variant="body2" sx={{ mt: 1 }}>
              Ellie will crawl up to 100 pages on the website and use the data when writing replies.
            </Typography>

            <Box mt={3}>
              <Typography gutterBottom>
                Exclude URLs
              </Typography>
              <Typography color="textSecondary" variant="body2" gutterBottom>
                If there are pages in the above website that you don't want Ellie to learn from, you can exclude them here:
              </Typography>
              {excludeUrls.map((url, index) => (
                <Box key={index} display="flex" alignItems="center" mt={1}>
                  <TextField
                    fullWidth
                    placeholder="https://example.com/terms"
                    value={url}
                    onChange={(e) => handleExcludeUrlChange(index, e.target.value)}
                    size="small"
                  />
                  {index > 0 && (
                    <IconButton 
                      size="small" 
                      onClick={() => handleRemoveExcludeUrl(index)}
                      sx={{ ml: 1 }}
                    >
                      <DeleteIcon />
                    </IconButton>
                  )}
                </Box>
              ))}
              <Button
                startIcon={<AddIcon />}
                onClick={handleAddExcludeUrl}
                sx={{ mt: 1 }}
              >
                Exclude another URL
              </Button>
            </Box>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenWebsiteDialog(false)}>Cancel</Button>
          <Button 
            onClick={handleWebsiteSubmit}
            variant="contained" 
            color="primary"
            disabled={!websiteUrl.trim()}
          >
            Start training
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}; 