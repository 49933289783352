import { Translations } from '../types';

const translations: Translations = {
  common: {
    loading: 'Laadimine...',
    error: 'Viga',
    success: 'Õnnestus',
    cancel: 'Tühista',
    navigation: {
      dashboard: 'Töölaud',
      roles: 'Rollid',
      knowledge: 'Teadmised',
      accounts: 'Kontod',
      team: 'Meeskond',
      settings: 'Seaded',
      logout: 'Logi välja',
    },
    header: {
      title: 'Administraatori töölaud',
      switchTheme: 'Lülitu {{mode}} režiimile',
    },
    labels: {
      name: 'Nimi',
      email: 'E-post',
      description: 'Kirjeldus',
      prompt: 'Viip',
      default: 'Vaikimisi',
      owner: 'Omanik',
    },
    actions: {
      add: 'Lisa',
      edit: 'Muuda',
      delete: 'Kustuta',
      confirm: 'Kas oled kindel?',
      copy: 'Kopeeri',
      remove: 'Eemalda',
    },
  },
  auth: {
    login: {
      title: 'Tere tulemast Ellie\'sse',
      subtitle: 'Logi sisse, et pääseda oma töölauale',
      email: 'E-post',
      password: 'Parool',
      signIn: 'Logi sisse',
      or: 'VÕI',
      continueWithGoogle: 'Jätka Google\'iga',
      continueWithMicrosoft: 'Jätka Microsoft\'iga',
      noAccount: 'Pole veel kontot?',
      signUp: 'Registreeru',
      initializing: 'Initsialiseerimine...',
      errors: {
        invalidCredentials: 'Vale e-post või parool',
        googleFailed: 'Google\'iga sisselogimine ebaõnnestus',
        microsoftFailed: 'Microsoft\'iga sisselogimine ebaõnnestus',
        initializationFailed: 'Autentimise initsialiseerimine ebaõnnestus',
        interactionInProgress: 'Teine sisselogimiskatse on pooleli. Palun oodake hetk ja proovige uuesti.',
        popupBlocked: 'Sisselogimise hüpikaken blokeeriti. Palun lubage hüpikaknad sellel saidil.',
        redirectFailed: 'Sisselogimislehele suunamine ebaõnnestus. Palun proovige uuesti.',
        accountSelectionFailed: 'Konto valimine ebaõnnestus. Palun proovige uuesti.',
        tokenAcquisitionFailed: 'Autentimistõendi hankimine ebaõnnestus. Palun proovige uuesti.'
      },
      messages: {
        redirecting: 'Suunamine sisselogimislehele...',
        authenticating: 'Autentimine...',
        loggingOut: 'Väljalogimine...'
      }
    }
  },
  accounts: {
    title: 'E-posti kontod',
    buttons: {
      connectGmail: 'Ühenda Gmail',
      connectOutlook: 'Ühenda Outlook',
    },
    emptyState: {
      title: 'Alusta konto ühendamisega',
      description: 'Ellie kategoriseerib automaatselt sinu e-kirjad ja loob vastuste mustandid. Ellie ei saada kunagi e-kirju ilma sinu kontrollita.',
    },
    status: {
      lastSynced: 'Viimati sünkroniseeritud: {{time}}',
    },
    actions: {
      disconnect: 'Katkesta ühendus',
    },
    messages: {
      disconnectConfirm: 'Kas oled kindel, et soovid katkestada ühenduse {{provider}} kontoga?',
      disconnectSuccess: '{{provider}} konto ühendus katkestatud',
      errors: {
        loadFailed: 'E-posti kontode laadimine ebaõnnestus',
        disconnectFailed: '{{provider}} konto ühenduse katkestamine ebaõnnestus',
        connectFailed: '{{provider}} kontoga ühendamine ebaõnnestus',
        authFailed: 'Autentimine ebaõnnestus',
      },
    },
  },
  dashboard: {
    title: 'Töölaud',
    welcome: 'Tere tulemast, {{name}}',
    stats: {
      usageStatistics: 'Kasutusstatistika',
      repliesToday: 'Tänased vastused',
      totalReplies: 'Kokku vastuseid',
      costToday: 'Tänane kulu',
      teamInformation: 'Meeskonna info',
      plan: 'Pakett',
      members: 'Liikmed',
      role: 'Roll',
      teamOwner: 'Meeskonna omanik',
      teamMember: 'Meeskonna liige',
      trainingStatus: 'Treeningu olek',
      status: 'Olek',
      messagesProcessed: 'Töödeldud sõnumid',
      processingCost: 'Töötlemise kulu',
      tokenUsage: 'Tokenite kasutus',
      promptTokens: 'Viiba tokenid',
      completionTokens: 'Vastuse tokenid',
      totalTokens: 'Kokku tokeneid',
    },
  },
  roles: {
    title: 'Rollid',
    addRole: 'Lisa roll',
    editRole: 'Muuda rolli',
    defaultRole: 'Vaikimisi',
    setAsDefault: 'Määra vaikimisi rolliks',
    clearDefault: 'Eemalda vaikimisi roll',
    deleteConfirm: 'Kas oled kindel, et soovid selle rolli kustutada?',
    messages: {
      fetchError: 'Rollide laadimine ebaõnnestus',
      createError: 'Rolli loomine ebaõnnestus',
      updateError: 'Rolli uuendamine ebaõnnestus',
      deleteError: 'Rolli kustutamine ebaõnnestus',
      setDefaultSuccess: '{{name}} on nüüd vaikimisi roll',
      clearDefaultSuccess: '{{name}} ei ole enam vaikimisi roll',
    },
  },
  knowledge: {
    title: 'Teadmised',
    websiteTraining: {
      title: 'Treeni Elliet veebilehelt',
    },
  },
  team: {
    title: 'Meeskonna haldus',
    addMember: {
      title: 'Lisa meeskonnaliige',
      button: 'Lisa liige',
    },
    inviteLink: {
      title: 'Meeskonna kutselink',
      description: 'Jaga seda linki inimestega, keda soovid meeskonda kutsuda',
      copied: 'Kutselink kopeeritud',
    },
    stats: {
      title: 'Meeskonna statistika',
      totalMembers: 'Kokku liikmeid',
      allowedMembers: 'Lubatud liikmeid',
      availableSlots: 'Vabu kohti',
    },
    member: {
      joined: 'Liitus: {{date}}',
      ownerLabel: 'Omanik',
      ownerCannotBeRemoved: 'Meeskonna omanikku ei saa eemaldada',
      removeConfirm: 'Kas oled kindel, et soovid eemaldada liikme {{name}}?',
    },
    messages: {
      fetchError: 'Meeskonna andmete laadimine ebaõnnestus',
      addSuccess: 'Meeskonnaliige edukalt lisatud',
      addError: 'Meeskonnaliikme lisamine ebaõnnestus',
      removeSuccess: 'Meeskonnaliige edukalt eemaldatud',
      removeError: 'Meeskonnaliikme eemaldamine ebaõnnestus',
    },
  },
  settings: {
    title: 'Seaded',
  },
};

export default translations; 